import React, { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/react-hooks";
import { Accordion, Card, Button } from "react-bootstrap";
import MachineDetails from "./components/MachineDetails";
import CarbonContent from "./components/CarbonContent";
import PowderMfg from "./components/PowderMfg";
import InertGass from "./components/InertGass";
import ElectricityConsumption from "./components/ElectricityConsumption";
import Transportation from "./components/Transportation";
import PostProcessing from "./components/PostProcessing";
import WastePowder from "./components/WastePowder";
import ArrowDown from "./components/ArrowDown";
import { Spinner } from "react-bootstrap";
import { CostData } from "atoms/CostToCarbonData";
import { ProcessFlag } from "atoms/General";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  technologyId,
  stepTypeId,
  technologyType,
} from "atoms/General/index.js";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const CarbonMetalProcess = ({ ScenarioState, socket, staticDataOfMachine }) => {
  const client = useApolloClient();
  const [clientSteps, setClientSteps] = useState([]);
  const [loader, setLoader] = useState(true);
  const costData = useRecoilValue(CostData);
  const staticCostData = JSON.stringify(costData);
  const processFlag = useRecoilValue(ProcessFlag);

  const [techId, setTechId] = useRecoilState(technologyId);
  const [steptypeId, setStepTypeId] = useRecoilState(stepTypeId);
  const [TechnologyType, settechnologyType] = useRecoilState(technologyType);
  // Getting the process items Query...
  const GET_CLIENT_STEPS = gql`
    query ($technology_id: Int, $type_id: Int) {
      getClientSteps(technology_id: $technology_id, type_id: $type_id) {
        step_id
        name
        description
        selected_icon
        unselected_icon
        is_blocked
        step_type_id {
          step_type_id
          name
          description
          is_blocked
          is_premium
        }
        technology_id {
          technology_id
          name
        }
      }
    }
  `;

  useEffect(() => {
    setTechId(2);
    setStepTypeId(4);
    settechnologyType("CO2e Metal");
    client
      .query({
        query: GET_CLIENT_STEPS,
        variables: { technology_id: 1, type_id: 4 },
      })
      .then(({ data, loading }) => {
        // console.log("Client Steps: ", data.getClientSteps);
        setClientSteps(data.getClientSteps);
        // getCarbonPolyerStepID(
        //   data.getClientSteps[data.getClientSteps.length - 1]
        // );
        setLoader(false);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [activeState, setActiveState] = useState(-1);
  const toggleState = (idnum) => {
    const id = idnum;
    if (activeState === +id) {
      setActiveState(null);
    } else {
      setActiveState(+id);
    }
  };

  // console.log("CostData in state : StringFy: ", staticCostData);
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spinner animation="border" />{" "}
        </div>
      ) : (
        <>
          {" "}
          <Accordion>
            {clientSteps &&
              clientSteps.map((step, index) => {
                return (
                  <Card key={index}>
                    {step?.step_id <= 74 && (
                      <Card.Header
                        className={activeState === index ? "active" : ""}
                      >
                        <div className="d-flex justify-content-between">
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            className={
                              activeState === index ? "active-color" : ""
                            }
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                            data-display="static"
                          >
                            {step?.name.includes("not") ? (
                              <TooltipTrippy
                                key={index}
                                tooltipMessage={step?.name}
                                fieldName={step.name}
                              />
                            ) : (
                              step.name
                            )}
                          </Accordion.Toggle>
                          <Accordion.Toggle
                            onClick={() => toggleState(index)}
                            id="0"
                            as={Button}
                            variant="link"
                            eventKey={index === 0 ? "0" : index}
                          >
                            <div
                              className={
                                activeState === index
                                  ? "arrow-box active"
                                  : "arrow-box"
                              }
                            >
                              <ArrowDown />
                            </div>
                          </Accordion.Toggle>
                        </div>
                      </Card.Header>
                    )}
                    <Accordion.Collapse
                      data-target={index}
                      eventKey={index === 0 ? "0" : index}
                    >
                      <Card.Body>
                        {step?.step_id === 68 &&
                          (activeState === index &&
                            processFlag.MachineDetails ? (
                            <MachineDetails StepID={68} />
                          ) : (
                            <p>Machine Manufacturing (currently not available)</p>
                          ))}

                        {step?.step_id === 69 && activeState === index && (
                          // <PoweredMFG StepID={78} />
                          <PowderMfg
                            Socket={socket}
                            staticDataOfMachine={staticDataOfMachine}
                          />
                        )}
                        {step?.step_id === 70 &&
                          (activeState === index &&
                            processFlag.PowderManufacturing ? (
                            <InertGass
                              Socket={socket}
                              staticDataOfMachine={staticDataOfMachine}
                            />
                          ) : (
                            <p>Fill all powder manufacturing details to view</p>
                          ))}

                        {step?.step_id === 71 &&
                          (activeState === index && processFlag.InertGas ? (
                            <ElectricityConsumption
                              Socket={socket}
                              staticDataOfMachine={staticDataOfMachine}
                            />
                          ) : (
                            <p>Fill all inert gas details to view</p>
                          ))}
                        {step?.step_id === 72 &&
                          (activeState === index &&
                            processFlag.ElectricityConsumption ? (
                            <Transportation
                              Socket={socket}
                              staticDataOfMachine={staticDataOfMachine}
                            />
                          ) : (
                            <p>
                              Fill all electricity consumption detials to view
                            </p>
                          ))}
                        {step?.step_id === 73 &&
                          (activeState === index &&
                            processFlag.PostProcessing ? (
                            // <PowderMfg Socket={socket} />
                            <PostProcessing />
                          ) : (
                            <p>Post Processing (currently not available)</p>
                          ))}
                        {step?.step_id === 74 &&
                          (activeState === index && processFlag.WastePowder ? (
                            <WastePowder />
                          ) : (
                            <p>Used Powder - End of Life (currently not available)
                            </p>
                          ))}

                        {/* {step?.step_id === 75 &&
                          (activeState === index &&
                            processFlag.Transportation ? (
                            <CarbonContent
                              Socket={socket}
                              staticDataOfMachine={staticDataOfMachine}
                            />
                          ) : (
                            <p>Fill All Transpotation Detials To View</p>
                          ))} */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })}
          </Accordion>
          <button
            onClick={() => {
              if (processFlag.Transportation) {
                ScenarioState();
              } else {
                toast.error("Please fill all process details.");
              }
            }}
            className="selection-button step-button"
          >
            Proceed
          </button>
        </>
      )}
    </>
  );
};

export default CarbonMetalProcess;
