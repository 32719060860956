/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Navbar from '../components/Nav.jsx';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useAccess } from 'state/hooks.js';
import { useHistory } from 'react-router-dom';
import CustomModal from 'app/components/Modals/Modals.jsx';

// const queryString = require('query-string');

const GET_TECHNOLOGIES = gql`
  query GetTechnologiesByUserGroup($group_id: Int!) {
    getTechnologiesByUserGroup(group_id: $group_id) {
      user_group_step_access_id
      created_at
      group_id {
        group_id
        name
      }
      technology_id {
        technology_id
        name
      }
    }
  }
`;

const Calculator = () => {
  let Access = useAccess();
  // console.log('Access', Access);
  const history = useHistory();

  // const parse = queryString.parse(window.location.search);
  let group_id = +sessionStorage.getItem('group_id');
  // const region = parse.regionName;
  const client = useApolloClient();
  const [tech, setTech] = useState([]);
  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState('');

  React.useEffect(() => {
    client
      .query({
        query: GET_TECHNOLOGIES,
        variables: { group_id },
      })
      .then((res) => {
        setTech(res.data.getTechnologiesByUserGroup);
        setLoading(true);
      })
      .catch((error) => {
        if (
          error.graphQLErrors[0].extensions.exception.response.status === 401 &&
          sessionStorage.getItem('signInstatus') === 'true'
        ) {
          toast.error(
            error.graphQLErrors[0].extensions.exception.response.message
          );
          window.location.href = '/';
        }
      });
  }, [client, group_id, tech]);




  const renderTech = () => {
    return tech.map((technology) => {
      return (
        <React.Fragment key={technology.user_group_step_access_id}>
          <div
            className="col-lg-3 col-md-6 center ml-auto"
            onClick={() => {
              technology.technology_id.name === 'Polymer'
                ? setKey('select1')
                : setKey('select3');
              sessionStorage.setItem(
                'technologyName',
                technology.technology_id.name + ' (Estimation)'
              );
              sessionStorage.setItem(
                'technology',
                technology.technology_id.name
              );
              sessionStorage.setItem('stepTypeId', 1);
              console.log(
                'technologyName',
                technology.technology_id.name + ' (Estimation)'
              );
              console.log('technology', technology.technology_id.name);
              // processNow();
            }}
          >
            <div
              className={
                'type-btn deadcenter ' +
                (key === 'select1' &&
                  technology.technology_id.name === 'Polymer'
                  ? ' type-selected'
                  : key === 'select3' &&
                    technology.technology_id.name === 'Metal'
                    ? 'type-selected'
                    : '')
              }
            >
              {technology.technology_id.name === 'Polymer' ? (
                <img src="/assets/polymer.png" width="25" height="25" alt="" />
              ) : (
                <img src="/assets/metal.png" width="25" height="25" alt="" />
              )}
              <p>{technology.technology_id.name} (Estimation)</p>
            </div>
          </div>
          {Access ? (
            <div
              className="col-lg-3 col-md-6 center mr-auto"
              onClick={() => {
                technology.technology_id.name === 'Polymer'
                  ? setKey('select2')
                  : setKey('select4');
                sessionStorage.setItem(
                  'technologyName',
                  technology.technology_id.name + ' (Simulation)'
                );
                sessionStorage.setItem(
                  'technology',
                  technology.technology_id.name
                );
                sessionStorage.setItem('stepTypeId', 2);
                console.log(
                  'technologyName',
                  technology.technology_id.name + ' (Simulation)'
                );
                console.log('technology', technology.technology_id.name);
                // processNow();
              }}
            >
              <div
                className={
                  'type-btn deadcenter ' +
                  (key === 'select2' &&
                    technology.technology_id.name === 'Polymer'
                    ? ' type-selected'
                    : key === 'select4' &&
                      technology.technology_id.name === 'Metal'
                      ? 'type-selected'
                      : '')
                }
              >
                {technology.technology_id.name === 'Polymer' ? (
                  <img
                    src="/assets/polymer.png"
                    width="25"
                    height="25"
                    alt=""
                  />
                ) : (
                  <img src="/assets/metal.png" width="25" height="25" alt="" />
                )}
                <p>{technology.technology_id.name} (Simulation)</p>
              </div>
            </div>
          ) : (
            <div
              className="col-lg-3 col-md-6 center mr-auto freemium-bg"
              onClick={() => { }}
            >
              <a href="/premium-request" className="premium">
                Premium
              </a>
              <div
                className={
                  'disable-btn  deadcenter ' +
                  (key === 'select2' &&
                    technology.technology_id.name === 'Polymer'
                    ? ' type-selected'
                    : key === 'select4' &&
                      technology.technology_id.name === 'Metal'
                      ? 'type-selected'
                      : '')
                }
              >
                {technology.technology_id.name === 'Polymer' ? (
                  <img
                    src="/assets/polymer.png"
                    width="25"
                    height="25"
                    alt=""
                  />
                ) : (
                  <img src="/assets/metal.png" width="25" height="25" alt="" />
                )}
                <p>{technology.technology_id.name} (Simulation)</p>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  const processNow = () => {
    if (key !== '') {
      window.location.href = `/calculator/process`;
    }
  };

  return (
    <React.Fragment>
      <Navbar />
      {loading ? (
        <div className="row fix-height">
          <div className="bg-wrap position-relative">
            <div className="bg-intro d-flex align-items-center p-0">
              <img
                src="/assets/images/background/DMLS_Technology_s00.50.17.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="calc_welcome_main">
              <div id="calc_welcome_box" className="row calc_welcome_wrap pt-0">
                <div className="col">
                  <p className="t-2 text-center">
                    Welcome to the Cost & Carbon Calculator
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div id="info-box" className="d-none">
                    {/* <ul>
                    <li>
                      Estimation: Minimal input variables needed (±20% accuracy)
                    </li>
                    <li>
                      Simulation (Calculation): Built time simulation required
                      (±5% accuracy)
                    </li>
                  </ul> */}
                    <p>
                      Try to use realistic inputs using the override function
                    </p>
                    <p className="green-businees-text">
                      Under Simulation you will have the possibility to also
                      calculate carbon footprint of your application for
                      selected machine and materials in addition to cost per
                      part. The tool allows the user to create a green business
                      using Carbon Pricing
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col page-container d-none">
                <p className="welcome-heading">
                  If you are not interested in cost per part but rather only
                  carbon footprint of your applications then click the following
                  buttons
                </p>
              </div>
            </div>
            {/* <div className="row" id="btn-row">
              {renderTech()}
            </div> */}
          </div>
          <div className="container select-machine">
            <div>
              {/* <div className="row">
                <div className="col deadcenter m-t-30">
                  <button className="button1" onClick={processNow}>
                    <p>Proceed Now</p>
                  </button>
                </div>
              </div> */}
              <div className="row page-container">
                <div className="col-md-6">
                  <div className="btn-contain d-flex align-items-center">
                    <img
                      src="/assets/images/background/EOS_Material_Copper_27.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="btn-container">
                    <CustomModal
                      className="selection-button m-0"
                      typeModal="Metal"
                      btnTitle="Metal"
                      techData={tech}
                    />
                    {/* <button
                      className="selection-button m-0"
                      onClick={() => {
                        history.push('/carboncalculator/metal-steps');
                      }}
                    >
                      CO2e(Metal)
                    </button> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="btn-contain d-flex align-items-center">
                    <img
                      src="/assets/images/background/Orthose_Pulver_01.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="btn-container">
                    <CustomModal
                      className="selection-button m-0"
                      typeModal="Polymer"
                      btnTitle="Polymer"
                      techData={tech}
                    />
                    {/* <button
                      className="selection-button"
                      onClick={() => {
                        history.push('/carboncalculator/polymer-steps');
                      }}
                    >
                      CO2e(Polymer)
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row pad-50 fill" id="map">
          <div className="col p-0 deadcenter ">
            <img
              className="mt-n5"
              src="./Spinner.svg"
              alt="loader"
              height="100px"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Calculator;
