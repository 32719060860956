import React from 'react'
import ButtonTab from './ButtonTab'
import DataMatricCommonRepresenter from './DataMatricCommonRepresenter'
import { DATAMATRIX } from 'app/components/ConditionalEnums'

const PolmerDataMatrix = ({ ScenarioState }) => {
    return (
        <div className="row pad-50 bg-light">
            <div className="col page-container px-0">
                <ButtonTab ScenarioState={ScenarioState} TabName="Switch to Metal" />


                <p className="t-2 text-center">Polymer</p>
                <div className='overflow-x-scroll'>
                    <table class="table table-bordered border-dark table-hover text-center text-justify bg-light text-alignment table-responsive">
                        <caption className='ml-1'>{DATAMATRIX.REPRESNETER}</caption>
                        <thead>
                            <tr className='table-border'>
                                <th className='border border-dark' scope="col" colSpan={2} rowSpan={2}>Process Step</th>
                                <th className='border border-dark' scope="col" colSpan={6}>Sourcing</th>
                                <th className='border border-dark' scope="col" colSpan={11}>AM Process</th>
                                <th className='border border-dark' scope="col">Transportation</th>
                                <th className='border border-dark' scope="col">Post Processing</th>
                                <th className='border border-dark' scope="col" >Use Phase</th>
                                <th className='border border-dark' scope="col">End of Life</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='table-border'>
                                <td colSpan={2} scope='row' ></td>
                                <td className='font-weight-bold' colSpan={2}><div>Powder & Material Sourcing</div> (inlcudes <div className='white_space-nowrap'>transportation)</div></td>
                                <td className='font-weight-bold' colSpan={2}>Machine Manufacturing (Includes sourcing, <div className='white_space-nowrap'>transportation)</div>, assembly and testing) </td>
                                <td className='font-weight-bold' colSpan={2}>Inert Gas Sourcing</td>
                                <td className='font-weight-bold' colSpan={2}>Compressed Air</td>
                                <td className='font-weight-bold'>External Nitrogen</td>
                                <td className='font-weight-bold' colSpan={2}>Powder <div className='white_space-nowrap'>consumption</div></td>
                                <td className='font-weight-bold' colSpan={2}>Powderloss</td>
                                <td className='font-weight-bold'>Powder Sieving and Processing</td>
                                <td className='font-weight-bold' >Powder Removal & Cleaning</td>
                                <td className='font-weight-bold'>Powder Atomization</td>
                                <td className='font-weight-bold'>Electricity Consumpton</td>
                                <td className='font-weight-bold'>EOS to Customer</td>
                                <td className='font-weight-bold'>Sand Blasting / Heat Treatment etc.</td>
                                <td className='font-weight-bold'>What is the <div className='white_space-nowrap'>application</div> used for</td>
                                <td className='font-weight-bold'>Recylced, Incinerated</td>
                            </tr>
                            {/* TODO... */}
                            {/* <tr className='table-border'>
                            <th scope="row">Data Source</th>
                            <td ></td>
                            <td ></td>
                            <td >To be calculated based on Academic Papers or by an LCA Institute</td>
                            <td ></td>
                            <td >Calculated on P1 & P7 on PA2200 </td>
                            <td ></td>
                            <td >Calculated under CPP, based on application measurements</td>
                            <td >Calculated under CPP, based on assumptions</td>
                            <td ></td>
                            <td >Use of Vacuum cleaner</td>
                            <td >Data from our powder suppliers on PA11 & PA12</td>
                            <td >Calculated on P1 & P7 on PA2200</td>
                            <td >ADEME Database</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}
                            {/* TODO.... Year Row */}
                            {/* <tr className='table-border'>
                            <th scope="row">Year</th>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}
                            {/* TODO.... Unit Row */}
                            {/* <tr className='table-border'>
                            <th scope="row">Unit</th>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td >Kg</td>
                            <td >% of part weight</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td >Watt</td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                            <td ></td>
                        </tr> */}

                            <tr className='table-border'>
                                <td >Data Source</td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}>To be calculated based on Academic Papers or by an LCA Institute</td>
                                <td colSpan={2}></td>
                                <td colSpan={2}>Data Sheet</td>
                                <td ></td>
                                <td colSpan={2}><div>Calculated under CPP, based on </div><div className='white_space-nowrap'>application</div> <div className='white_space-nowrap'>measurements</div></td>
                                <td colSpan={2}>Calculated under CPP, based on <div className='white_space-nowrap'>assumptions</div></td>
                                <td ></td>
                                <td ></td>
                                <td >Data from our powder suppliers on PA11 & PA12</td>
                                <td >Data Sheet </td>
                                <td >ADEME Database</td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                            </tr>
                            <tr className='table-border'>
                                <td >Year</td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td colSpan={2} ></td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                            </tr>
                            <tr className='table-border'>
                                <td >Unit</td>
                                <td ></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td ></td>
                                <td colSpan={2}>Kg</td>
                                <td colSpan={2}>% of part</td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td >Watt</td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                                <td ></td>
                            </tr>

                            {/* P110 Row */}
                            <tr className='table-border'>
                                <th rowSpan={11} scope="row">P110</th>
                                <td >Alumide</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange' ></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={2} ></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>
                            {/* nested row 2 of P110 */}
                            <tr className='table-border'>
                                <td >EOSPEEKHP3</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            {/* nested row 3 of P110 */}
                            <tr className='table-border'>
                                <td >PA1101</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>
                            {/* nested row 4 of P110 */}

                            <tr className='table-border'>
                                <td >PA1102black</td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={2} ></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            {/* nested row 5 of P110 */}
                            <tr className='table-border'>
                                <td >PA2105</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>
                            {/* nested row 6 of P110 */}
                            <tr className='table-border'>
                                <td >PA2200</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2201</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={7}></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2202black</td>
                                <td className='bg-gray' colSpan={2} ></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2210FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2241FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA3200GF</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            {/* Machine p396 */}

                            <tr className='table-border'>
                                <td scope='row' rowSpan={11} >P396</td>
                                <td >Alumide</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >EOSPEEKHP3</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA1101</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA1102black</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={2}></td>
                                <td ></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2105</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2200</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2201</td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={5}></td>
                                <td ></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2202black</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2210FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2241FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA3200GF</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td scope='row' >P500</td>
                                <td >PA2200</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td scope='row' rowSpan={11} >P770</td>
                                <td >Alumide</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={2}></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >EOSPEEKHP3</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA1101</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA1102black</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={2}></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2105</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2200</td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2201</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td rowSpan={6}></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2202black</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2210FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA2241FR</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td >PA3200GF</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td className='bg-green'></td>
                                <td className=' bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                            <tr className='table-border'>
                                <td scope='row' >P810</td>
                                <td >HT 23</td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td className=' bg-gray' colSpan={2}></td>
                                <td colSpan={2}></td>
                                <td className='bg-blue'></td>
                                <td className='bg-green' colSpan={2}></td>
                                <td className='bg-orange' colSpan={2}></td>
                                <td className='bg-orange'></td>
                                <td className=' bg-gray'></td>
                                <td ></td>
                                <td className='bg-yellow'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-blue'></td>
                                <td className='bg-gray'></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-center'>
                    <DataMatricCommonRepresenter caption="Polymer" />
                </div>
            </div>
        </div >
    )
}

export default PolmerDataMatrix