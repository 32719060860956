import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { ToastContainer, toast } from 'react-toastify';

const REQUEST_PREMIUM_USER = gql`
mutation premiumRequest($user_id: Int!,$status:Int! ){
    premiumRequest(user_id:$user_id,status:$status){
      premium_id
    }
}
`;

const PremiumRequestPage = () => {
  const [Loading, setLoading] = useState(false);
  let userId = sessionStorage.getItem('user_id');

  const [premiumRequestMutation] = useMutation(REQUEST_PREMIUM_USER);

  const sendResetEmail = async () => {

    setLoading(true);

    await premiumRequestMutation({
      variables: { user_id: +userId, status: 2 },
      optimisticResponse: true,
    }).then(res => {
      toast('Premium Request Submitted Successfully')
      setLoading(false)
    }).catch(error => {
      const {
        graphQLErrors,
        networkError
      } = error;

      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          const { exception } = extensions;
          if (exception.message && typeof exception.message !== 'string') {
            toast(exception.message[0]);
          } else {
            toast(exception.message);
          }
        }
        );
      } else if (networkError) {
        toast(`[Network error]: ${networkError}`);
      }
      //false loading
      setLoading(false)
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row fill">
        <div className="col-lg-6 login-container">
          <div>
            <p className="login-text">Press the button to request admin for premium access.</p>
            <button className="button1" onClick={sendResetEmail} disabled={Boolean(Loading)}>
              {!Loading ? <p>Request Premium</p> : <p>Loading</p>}
            </button>
          </div>
        </div>
        <div className="col-lg-6 coverimage image3"></div>
      </div>
    </React.Fragment>
  )
}

export default PremiumRequestPage;