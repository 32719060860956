import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Co2EmissionFactorDataM,
  Co2EmissionBuildProcessDataM,
  Co2EmissionPowderDataM,
  Co2EmissionGasDataM,
  Co2EmissionTransportationDataM,
} from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { PowderTypeDataM } from "atoms/Co2Metal/PowderMfgAtoms";
import { MaterialDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { Co2ePerPartPMDataM } from "atoms/Co2Metal/PowderMfgAtoms";
import { EnergyPerPartDataM } from "atoms/Co2Metal/ElectricityConsumptionAtoms";
import { InertGasUsedEnergyDataM } from "atoms/Co2Metal/InertGasAtoms";
import { NoOfPartsDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { Co2ePerPartTMDataM } from "atoms/Co2Metal/TransportationAtoms";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";

const CarbonContentPerPart = ({ Socket }) => {
  const [co2EmissionFactor, setCo2EmissionFactor] = useRecoilState(
    Co2EmissionFactorDataM
  );
  const [co2EmissionBP, setCo2EmissionBP] = useRecoilState(
    Co2EmissionBuildProcessDataM
  );
  const [co2EmissionPowder, setCo2EmissionPowder] = useRecoilState(
    Co2EmissionPowderDataM
  );
  const [co2EmissionGas, setCo2EmissionGas] =
    useRecoilState(Co2EmissionGasDataM);
  const [co2EmissionTransportation, setCo2EmissionTransportation] =
    useRecoilState(Co2EmissionTransportationDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);
  const atomizationType = useRecoilValue(PowderTypeDataM)

  const Co2ePerPartPM = useRecoilValue(Co2ePerPartPMDataM);
  const EnergyPerPart = useRecoilValue(EnergyPerPartDataM);
  const inertGasUsedEnergy = useRecoilValue(InertGasUsedEnergyDataM);
  const noOfPartsData = useRecoilValue(NoOfPartsDataM);
  const co2PerPartTM = useRecoilValue(Co2ePerPartTMDataM);
  const materialId = useRecoilValue(MaterialDataM)
  const { getComputedValue } = useRoundOffValue();
  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
  });

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderone: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-CCPPCEF", input: "" });

    Socket.on("DBCC-CCPPCEF", function (data) {

      const value = getComputedValue(data?.data?.value);
      //CHECKED
      setCo2EmissionFactor(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: false,
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (co2PerPartTM) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCET",
        input: { co2e_per_part_tm: co2PerPartTM },
      });

      Socket.on("FOCC-CCPPCET", function (data) {

        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionTransportation(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (co2EmissionFactor && Co2ePerPartPM && materialId && atomizationType) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEP",
        input: {
          co2_emission_factor: co2EmissionFactor,
          co2e_per_part: Co2ePerPartPM,
          material_id: materialId,
          automization_type: atomizationType
        },
      });

      Socket.on("FOCC-CCPPCEP", function (data) {

        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionPowder(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderthree: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EmissionFactor, Co2ePerPartPM, materialId, atomizationType]);

  useEffect(() => {
    if (inertGasUsedEnergy && noOfPartsData && co2EmissionFactor) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });


      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEG",
        input: {
          inert_gas_used_energy: inertGasUsedEnergy,
          co2_emission_factor: co2EmissionFactor,
          no_of_parts: noOfPartsData,
          energy_per_part: EnergyPerPart,
        },
      });

      Socket.on("FOCC-CCPPCEG", function (data) {

        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionGas(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasUsedEnergy, noOfPartsData, co2EmissionFactor]);

  useEffect(() => {
    if (co2EmissionFactor && EnergyPerPart) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEBP",
        input: {
          co2_emission_factor: co2EmissionFactor,
          energy_per_part: EnergyPerPart,
        },
      });

      Socket.on("FOCC-CCPPCEBP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2EmissionBP(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfive: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EmissionFactor, EnergyPerPart]);

  useEffect(() => {
    if (
      co2EmissionFactor === "" ||
      co2EmissionBP === "" ||
      co2EmissionPowder === "" ||
      co2EmissionGas === "" ||
      co2EmissionTransportation === ""
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonContentPP: false,
        };
      });
    } else if (
      co2EmissionFactor !== undefined &&
      co2EmissionBP !== undefined &&
      co2EmissionPowder !== undefined &&
      co2EmissionGas !== undefined &&
      co2EmissionTransportation !== undefined
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonContentPP: true,
        };
      });
    } else {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonContentPP: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2EmissionPowder,
    co2EmissionFactor,
    co2EmissionBP,
    co2EmissionGas,
    co2EmissionTransportation,
  ]);

  return (
    <>
      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (g/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />
      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2EmissionFactor(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e emission factor"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              co2EmissionFactor
            )
          }
        />
        <OverrideAbleTip />
      </div>

      {
      // TODO Co2e emission build process not being shown now 
      /* <p className="input-heading">CO2e emission build process (kg)</p>
      <div className="data-field">
        {loader.loaderfive ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2EmissionBP
        )}
      </div> */}
      <p className="input-heading">CO2e powder (kg)</p>
      <div className="data-field">
        {loader.loaderthree ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2EmissionPowder
        )}
      </div>
      <p className="input-heading">CO2e energy (kg)</p>

      <div className="data-field ">
        {loader.loaderfour ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2EmissionGas
        )}
      </div>
      <p className="input-heading">CO2e logistics (kg)</p>

      <div className="data-field ">
        {loader.loadertwo ? (
          <Spinner animation="border" size="sm" />
        ) : (
          co2EmissionTransportation
        )}
      </div>
    </>
  );
};

export default CarbonContentPerPart;
