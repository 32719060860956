import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState } from "recoil";
import { DebounceInput } from "react-debounce-input";
import {
  CCPolyTimeForProcessingData,
  CCPolyAverageEnergyData,
  CCPolyCO2EmissionFactorData,
  CCPolyCo2ePerJobMPData,
  CCPolyCo2ePerPartMPData,
} from "atoms/CCPolymer/CCPolyMaterialProcessing";
import { CCPolyPowderPerBuildJobData } from "atoms/CCPolymer/CCPolyPowderMFG";
import { ProcessFlag } from "atoms/General";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const MaterialProcessing = ({ Socket, StepID }) => {
  const [timeForp, setTimeforp] = useRecoilState(CCPolyTimeForProcessingData);
  const [averageenergy, setAverageenergy] = useRecoilState(
    CCPolyAverageEnergyData
  );
  const [co2ef, setCo2ef] = useRecoilState(CCPolyCO2EmissionFactorData);
  const [co2pj, setCo2pj] = useRecoilState(CCPolyCo2ePerJobMPData);
  const [co2pp, setCo2pp] = useRecoilState(CCPolyCo2ePerPartMPData);
  const [powderPerBuildJob, setPowderPErBuildJob] = useRecoilState(
    CCPolyPowderPerBuildJobData
  );
  const { getComputedValue } = useRoundOffValue();
  const costData = JSON.parse(localStorage.getItem("CostData"));
  const [, setProcessFlag] = useRecoilState(ProcessFlag);

  const [loader, setLoader] = useState({
    loaderone: false,
    loadertwo: false,
    loaderthree: false,
    loaderfour: false,
    loaderfive: false,
  });

  useEffect(() => {
    if (powderPerBuildJob !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderone: true,
        };
      });

      Socket.emit("carbon-calculator", { key: "DBCC-MPTFP", input: "" });

      Socket.on("DBCC-MPTFP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setTimeforp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderone: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderPerBuildJob]);

  useEffect(() => {
    if (timeForp !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loadertwo: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-MPAE",
        input: { time_for_processing: timeForp },
      });

      Socket.on("FOCC-MPAE", function (data) {

        const value = getComputedValue(data?.data?.value);

        setAverageenergy(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loadertwo: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeForp]);

  useEffect(() => {
    Socket.off("*");
    setLoader((prevState) => {
      return {
        ...prevState,
        loaderthree: true,
      };
    });
    Socket.emit("carbon-calculator", { key: "DBCC-MPCEF", input: "" });

    Socket.on("DBCC-MPCEF", function (data) {

      const value = getComputedValue(data?.data?.value);

      setCo2ef(value);
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderthree: false,
        };
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (averageenergy !== undefined && co2ef !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfour: true,
        };
      });
      Socket.emit("carbon-calculator", {
        key: "FOCC-MPCPJ",
        input: { average_energy: averageenergy, co2_emission_factor: co2ef },
      });

      Socket.on("FOCC-MPCPJ", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pj(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfour: false,
          };
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageenergy, co2ef]);
  useEffect(() => {
    if (co2pj !== undefined && costData.no_of_parts.value !== undefined) {
      Socket.off("*");
      setLoader((prevState) => {
        return {
          ...prevState,
          loaderfive: true,
        };
      });

      Socket.emit("carbon-calculator", {
        key: "FOCC-MPCPP",
        input: {
          co2e_per_job_mp: co2pj,
          no_of_parts: costData.no_of_parts.value,
        },
      });

      Socket.on("FOCC-MPCPP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2pp(value);
        setLoader((prevState) => {
          return {
            ...prevState,
            loaderfive: false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2pj]);

  useEffect(() => {
    if (
      powderPerBuildJob !== null &&
      timeForp !== null &&
      averageenergy !== null &&
      co2ef !== null &&
      co2pj !== null &&
      co2pp !== null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          MaterialProcessing: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderPerBuildJob, timeForp, averageenergy, co2ef, co2pj, co2pp]);
  return (
    <>
      <p className="input-heading">Powder per build job (kg) </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setPowderPErBuildJob(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter powder per build job"
          value={powderPerBuildJob}
        />
      </div>

      <p className="input-heading">Time for processing (h) </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setTimeforp(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter time for processing"
          value={
            loader.loaderone ? (
              <Spinner animation="border" size="sm" />
            ) : (
              timeForp
            )
          }
        />
        <OverrideAbleTip />
      </div>

      <p className="input-heading">Average energy (kWh)</p>
      <div className="data-field">
        {loader.loadertwo ? (
          <Spinner animation="border" size="sm" />
        ) : (
          averageenergy
        )}
      </div>
      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (kg/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />

      </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2ef(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e emission factor"
          value={
            loader.loaderthree ? (
              <Spinner animation="border" size="sm" />
            ) : (
              co2ef
            )
          }
        />
        <OverrideAbleTip />
      </div>
      {/* <div className="data-field">{co2ef}  <p className="edit-icon value-unit pr-5">kg/kwh</p></div> */}
      <p className="input-heading">CO2e per job (kg) </p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2pj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          placeholder="Please enter CO2e per job"
          value={
            loader.loaderfour ? <Spinner animation="border" size="sm" /> : co2pj
          }
        />
        <OverrideAbleTip />
      </div>
      {/* <div className="data-field">{co2pj}  <p className="edit-icon value-unit">kg</p></div> */}
      <p className="input-heading">CO2e per part (kg)</p>
      <div className="data-field">
        {loader.loaderfive ? <Spinner animation="border" size="sm" /> : co2pp}
      </div>
    </>
  );
};

export default MaterialProcessing;
