import React, { useEffect } from "react";

import {
  CCMetalPowderAutomizationData,
  CCMetalVolumePartReductionData,
  CCMetalCo2ePowderScenarioMData,
  CCMetalPowderAutomizationState,
  CCMetalGreenEnergyMixData,
  CCMetalInertGasReductionSMData,
  CCMetalCo2TotalTodaySMData,
  CCMetalCo2TotalTomorrowSMData,
  CCMetalVolumePartReductionState,
  CCMetalModeOfTransportNode1,
  CCMetalModeOfTransportNode2,
  CCMetalModeOfTranspotation1,
  CCMetalModeOfTranspotation2,
  CCMetalGreenEnergyMixDataInput,
  CCMetalTotalTraspotation
} from "atoms/CCCarbonMetal/CCMetalScenario";
import { Spinner } from "react-bootstrap";

import { useRecoilState } from "recoil";
import SelectDropdown from "app/components/SelectDropdown";
import {
  PercentageData,
  YesNoData,
  LogisticsData,
} from "utils/selectDropdowns";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
const { getComputedValue } = useRoundOffValue();

const MaterialTableValues = ({ socket, fieldObj }) => {
  const [, setPowderAutomization] = useRecoilState(
    CCMetalPowderAutomizationData
  );
  const [volumePartReduction, setVolumePartReduction] = useRecoilState(
    CCMetalVolumePartReductionData
  );
  const [co2ePowder, setCo2EPowder] = useRecoilState(
    CCMetalCo2ePowderScenarioMData
  );
  const [greenEnergyMix, setGreenEnergyMix] = useRecoilState(
    CCMetalGreenEnergyMixData
  );
  const [inertGasReduction, setInertGasReduction] = useRecoilState(
    CCMetalInertGasReductionSMData
  );

  const [, setCo2TotalToday] = useRecoilState(
    CCMetalCo2TotalTodaySMData
  );
  const [, setCo2TotalTomorrow] = useRecoilState(
    CCMetalCo2TotalTomorrowSMData
  );
  const [powderAtomizationState, setPowderAutomizationState] = useRecoilState(
    CCMetalPowderAutomizationState
  );

  const [modeOfTransportnode1, setmodeOfTransportnode1] = useRecoilState(
    CCMetalModeOfTransportNode1
  );

  const [modeOfTransportnode2, setmodeOfTransportnode2] = useRecoilState(
    CCMetalModeOfTransportNode2
  );

  const [modeOfTranspotation1, setModeOfTranspotation1] = useRecoilState(
    CCMetalModeOfTranspotation1
  );
  const [modeOfTranspotation2, setModeOfTranspotation2] = useRecoilState(
    CCMetalModeOfTranspotation2
  );

  const [totalTranpotation, setTotalTraspotation] = useRecoilState(
    CCMetalTotalTraspotation
  );

  const co2EmissionPowder = fieldObj?.co2EmissionPowder;
  const CCMetalCo2ePerPartPMData = fieldObj?.CCMetalCo2ePerPartPMData;
  const co2eEmissionFactor = fieldObj?.co2eEmissionFactor;
  const materialTransportaion = +fieldObj?.materialTransportaion;
  const systemTransportation = +fieldObj?.systemTransportation;
  const cefnone = fieldObj?.cefnone;
  const cefnTwo = fieldObj?.cefnTwo;
  const co2EmissionBuildProcess = fieldObj?.co2EmissionBuildProcess;
  const [volumerPartReductionState, setVolumePartReductionState] =
    useRecoilState(CCMetalVolumePartReductionState);

  const co2EmissionGas = fieldObj?.co2EmissionGas;

  const [greenEnergymixState, setGreenEnergyMixState] = useRecoilState(CCMetalGreenEnergyMixDataInput);

  useEffect(() => {
    setPowderAutomizationState(fieldObj?.powderAtomizationInput)
    setVolumePartReductionState(fieldObj?.volumerPartReductionStateInput)
    setGreenEnergyMixState(fieldObj?.greenEnergymixStateInput)
    setModeOfTranspotation1(fieldObj?.modeOfTranspotation1Input)
    setModeOfTranspotation2(fieldObj?.modeOfTranspotation2Input)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldObj])

  useEffect(() => {
    if (modeOfTranspotation1 && modeOfTranspotation2) {
      socket.emit("carbon-calculator", {
        key: "MSA-MCT",
        input: {
          node1_mode: modeOfTranspotation1,
          node2_mode: modeOfTranspotation2,
          material_transportation: parseInt(materialTransportaion),
          system_transportation: parseInt(systemTransportation),
          carbon_emission_factor_node_1: +cefnone,
          carbon_emission_factor_node_2: +cefnTwo,
          utilization: +fieldObj?.costData?.utilization?.value,
          build_time: +fieldObj?.costData?.build_time?.value,
          setup_time: +fieldObj?.costData?.setup_time?.value,
          // cool_down_time_in_system:
          //   +costData.cool_down_time_in_system.value, //not in metal
          unpacking_time_per_job:
            +fieldObj?.costData?.unpacking_time_per_job?.value,
        },
      });

      socket.on("MSA-MCT", function (data) {
        let value_node_1_today = data?.data?.transport_node_1_today.toFixed(3)
        let value_node_1_tomorrow = data?.data?.transport_node_1_tomorrow.toFixed(3)
        let value_node_2_today = data?.data?.transport_node_2_today.toFixed(3)

        let value_node_2_tomorrow = data?.data?.transport_node_2_tomorrow.toFixed(3)


        const transportNode1Today = value_node_1_today.slice(-1) === '0' ? data?.data?.transport_node_1_today.toFixed(5) : value_node_1_today
        const transportNode1Tomorrow = value_node_1_tomorrow.slice(-1) === '0' ? data?.data?.transport_node_1_tomorrow.toFixed(5) : value_node_1_tomorrow

        const transportNode2Today = value_node_2_today.slice(-1) === '0' ? data?.data?.transport_node_2_today.toFixed(5) : value_node_2_today
        const transportNode2Tomorrow = value_node_2_tomorrow.slice(-1) === '0' ? data?.data?.transport_node_2_tomorrow.toFixed(5) : value_node_2_tomorrow

        setmodeOfTransportnode1({
          today: transportNode1Today,
          tomorrow: transportNode1Tomorrow,
        });
        setmodeOfTransportnode2({
          today: transportNode2Today,
          tomorrow: transportNode2Tomorrow,
        });
        setTotalTraspotation({
          today: ((transportNode1Today.length > 5) || (transportNode2Today.length > 5)) ? data?.data?.transport_today.toFixed(5) : getComputedValue(data?.data?.transport_today),
          tomorrow: ((transportNode1Tomorrow.length > 5) || (transportNode2Tomorrow.length > 5)) ? data?.data?.transport_tomorrow.toFixed(5) : getComputedValue(data?.data?.transport_tomorrow),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeOfTranspotation1, modeOfTranspotation2]);

  useEffect(() => {
    if (powderAtomizationState && volumerPartReductionState) {
      socket.emit("carbon-calculator", {
        key: "MSA-MCP",
        input: {
          atomizedType: powderAtomizationState,
          co2_emission_powder: co2EmissionPowder,
          reduction_in_volume: volumerPartReductionState,
          powder_energy_per_part: CCMetalCo2ePerPartPMData,
          co2_emission_factor: co2eEmissionFactor,
          no_of_parts: parseInt(fieldObj?.costData?.no_of_parts?.value),
          part_volume: parseInt(fieldObj?.costData?.part_volume?.value),
          part_support_volume: parseInt(
            fieldObj?.costData?.part_support_volume?.value
          ),
          material_id: parseInt(fieldObj?.costData?.material_id?.value),
        },
      });

      socket.on("MSA-MCP", function (data) {
        const valuepatod = getComputedValue(
          data?.data?.powder_atomization_today
        );
        const valuepatom = getComputedValue(
          data?.data?.powder_atomization_tomorrow
        );
        const valuecptod = getComputedValue(data?.data?.co2e_powder_today);
        const valuecptom = getComputedValue(data?.data?.co2e_powder_tomorrow);
        const valuevptod = getComputedValue(
          data?.data?.volume_of_the_part_reduction_today
        );
        const valuevptom = getComputedValue(
          data?.data?.volume_of_the_part_reduction_tomorrow
        );
        setPowderAutomization({ today: valuepatod, tomorrow: valuepatom });
        setCo2EPowder({ today: valuecptod, tomorrow: valuecptom });
        setVolumePartReduction({ today: valuevptod, tomorrow: valuevptom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powderAtomizationState, volumerPartReductionState]);
  useEffect(() => {
    if (greenEnergymixState) {
      socket.emit("carbon-calculator", {
        key: "MSA-MCE",
        input: {
          isGreenEnergyMix: greenEnergymixState,
          co2_emission_build_process: co2EmissionBuildProcess,
        },
      });

      socket.on("MSA-MCE", function (data) {
        const valuepatod = getComputedValue(data.data.green_energy_mix_today);
        const valuepatom = getComputedValue(
          data.data.green_energy_mix_tomorrow
        );
        setGreenEnergyMix({ today: valuepatod, tomorrow: valuepatom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greenEnergymixState]);
  useEffect(() => {
    if (co2EmissionGas) {
      socket.emit("carbon-calculator", {
        key: "MSA-MCG",
        input: { co2_emission_gas: co2EmissionGas },
      });

      socket.on("MSA-MCG", function (data) {
        const valuepatod = getComputedValue(
          data.data.inert_gas_reduction_today
        );
        const valuepatom = getComputedValue(
          data.data.inert_gas_reduction_tomorrow
        );
        setInertGasReduction({ today: valuepatod, tomorrow: valuepatom });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      co2ePowder !== undefined &&
      greenEnergyMix !== undefined &&
      inertGasReduction !== undefined &&
      totalTranpotation !== undefined
    ) {
      const value =
        parseFloat(co2ePowder.today) +
        parseFloat(inertGasReduction.today) +
        parseFloat(greenEnergyMix.today) +
        parseFloat(totalTranpotation?.today);
      const value1 = getComputedValue(value);
      setCo2TotalToday(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2ePowder,
    inertGasReduction,
    volumePartReduction,
    greenEnergyMix,
    totalTranpotation,
  ]);
  useEffect(() => {
    if (
      co2ePowder !== undefined &&
      greenEnergyMix !== undefined &&
      inertGasReduction !== undefined &&
      totalTranpotation !== undefined
    ) {
      const value =
        parseFloat(co2ePowder.tomorrow) +
        parseFloat(inertGasReduction.tomorrow) +
        parseFloat(greenEnergyMix.tomorrow) +
        parseFloat(totalTranpotation?.tomorrow);
      const value1 = getComputedValue(value);

      setCo2TotalTomorrow(value1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2ePowder,
    inertGasReduction,
    volumePartReduction,
    greenEnergyMix,
    totalTranpotation,
  ]);

  return (
    <div className=" responsive-scroll">
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {/* <div className="divTableCell1-heading">&nbsp;</div> */}
            <div className="divTableCell1-heading p-1">Input</div>
            <div className="divTableCell1-heading pl-2">Current Scenario</div>
            <div className="divTableCell1-heading">Future Scenario</div>
          </div>
          {/* <div className="divTableRow">
                        <div className="divTableCell1 p-0">

                            <SelectDropdown setState={setPowderAutomizationState} getState={powderAtomizationState} Dataset={AtomizationDataMetal} styleclass="dropdown-input" />
                        </div>
                        <div className="divTableCell1 pl-3 position-top-0">
                            {powderAutomization ? powderAutomization.today : "_ _ _ _"}
                        </div>
                        <div className="divTableCell1 pl-3 position-top-0">
                            {powderAutomization ? powderAutomization.tomorrow : "_ _ _ _"}
                        </div>
                    </div>
                    TODO commented as of now as per client requirement
                    */}
          <div className="divTableRow">
            {/* <div className="divTable__Cells p-0">&nbsp;</div> */}
            <div className="divTable__Cells p-0">
              <SelectDropdown
                setState={setVolumePartReductionState}
                getState={volumerPartReductionState}
                Dataset={PercentageData}
                styleclass="dropdown-input p-0"
              />
            </div>
            <div className="divTable__Cells pl-3">
              {volumePartReduction ? volumePartReduction.today : "_ _ _ _"}
            </div>
            <div className="divTable__Cells pl-3">
              {volumePartReduction?.tomorrow !== undefined ?
                volumePartReduction?.tomorrow
                : <Spinner animation="border" size="sm" />
              }
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTable__Cells pl-3 position-top-0">
              {co2ePowder ? co2ePowder.today : "_ _ _ _"}
            </div>
            <div className="divTable__Cells position-top-0 pl-3">
              {co2ePowder?.tomorrow !== undefined ?
                co2ePowder?.tomorrow
                : <Spinner animation="border" size="sm" />}
            </div>
          </div>

          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableCell1 p-0">
            <SelectDropdown
              setState={setGreenEnergyMixState}
              getState={greenEnergymixState}
              Dataset={YesNoData}
              styleclass="dropdown-input p-0"
            />
          </div>
          <div className="divTable__Cells pl-3 position-top-0">
            {greenEnergyMix ? greenEnergyMix.today : "_ _ _ _"}
          </div>
          <div className="divTable__Cells position-top-0 pl-3">
            {greenEnergyMix?.tomorrow !== undefined ?
              greenEnergyMix?.tomorrow
              : <Spinner animation="border" size="sm" />}
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1-heading p-0">&nbsp;</div>
          <div className="divTableCell1-heading p-0">&nbsp;</div>
          <div className="divTableCell1-heading p-0">&nbsp;</div>
          <div className="divTableCell1-heading p-0">&nbsp;</div>
        </div>
        <div className="divTableRow">
          {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTable__Cells pl-3 position-top-0">
            {inertGasReduction ? inertGasReduction.today : "_ _ _ _"}
          </div>
          <div className="divTable__Cells position-top-0 pl-3">
            {
              inertGasReduction?.tomorrow !== undefined ?
                inertGasReduction?.tomorrow
                :
                <Spinner animation="border" size="sm" />
            }
          </div>
        </div>
        <div className="divTableRow">
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
        </div>

        <div className="divTableRow">
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTableCell1">&nbsp;</div>
        </div>

        <div className="divTableRow">
          {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
          <div className="divTableCell1 p-0 position-top-0">
            <SelectDropdown
              setState={setModeOfTranspotation1}
              getState={modeOfTranspotation1}
              Dataset={LogisticsData}
              styleclass="dropdown-input p-0 position-top-0"
            />
          </div>
          <div className="divTable__Cells pl-3 position-top-0">
            {modeOfTransportnode1 !== undefined
              ? modeOfTransportnode1.today
              : "_ _ _ _"}
          </div>
          <div className="divTable__Cells position-top-0 pl-3">
            {modeOfTransportnode1 !== undefined
              ?
              modeOfTransportnode1?.tomorrow
              : <Spinner animation="border" size="sm" />}
          </div>
        </div>

        <div className="divTableRow">
          {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
          <div className="divTableCell1 p-0">
            <SelectDropdown
              setState={setModeOfTranspotation2}
              getState={modeOfTranspotation2}
              Dataset={LogisticsData}
              styleclass="dropdown-input p-0"
            />
          </div>
          <div className="divTable__Cells pl-3 position-top-0">
            {modeOfTransportnode2 !== undefined
              ? modeOfTransportnode2.today
              : "_ _ _ _"}
          </div>
          <div className="divTable__Cells position-top-0 pl-3">
            {modeOfTransportnode2 !== undefined
              ?
              modeOfTransportnode2?.tomorrow
              : <Spinner animation="border" size="sm" />}
          </div>
        </div>

        <div className="divTableRow">
          {/* <div className="divTableCell1 p-0">&nbsp;</div> */}
          <div className="divTableCell1">&nbsp;</div>
          <div className="divTable__Cells pl-3 position-top-0">
            {totalTranpotation ? totalTranpotation?.today : "_ _ _ _"}
          </div>

          <div className="divTable__Cells position-top-0 pl-3">
            {totalTranpotation ?
              totalTranpotation?.tomorrow
              : <Spinner animation="border" size="sm" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTableValues;
