import { atom, selector } from "recoil";
import {
  PowderToCustomerDataM,
  SystemToCustomerDataM,
} from "../TransportationAtoms";

export const PowderAutomizationData = atom({
  key: "PowderAutomizationData",
  default: 0,
});

export const VolumePartReductionData = atom({
  key: "VolumePartReductionData",
  default: {
    today: undefined,
    tomorrow: undefined,
  },
});
export const Co2ePowderScenarioMData = atom({
  key: "Co2ePowderScenarioMData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const GreenEnergyMixData = atom({
  key: "GreenEnergyMixData",
  default: {
    today: 0,
    tomorrow: 0,
  },
});
export const InertGasReductionSMData = atom({
  key: "InertGasReductionSMData",
  default: {
    today: undefined,
    tomorrow: undefined,
  },
});
export const Co2ePowderMSData = atom({
  key: "Co2ePowderMSData",
  default: undefined,
});
export const Co2eTransportationSMData = atom({
  key: "Co2eTransportationSMData",
  default: undefined,
});

export const Co2TotalTodaySMData = atom({
  key: "Co2TotalTodaySMData",
  default: undefined,
});

export const Co2TotalTomorrowSMData = atom({
  key: "Co2TotalTomorrowSMData",
  default: undefined,
});

export const PowderAutomizationInput = atom({
  key: "PowderAutomizationInput",
  default: "GAS_ATOMIZED",
});

export const VolumePartReductionInput = atom({
  key: "VolumePartReductionInput",
  default: "0",
});

export const GreenEnergyMixInput = atom({
  key: "GreenEnergyMixInput",
  default: "no",
});

export const TotalTraspotation = atom({
  key: "Co2MetalTotalTraspotation",
  default: undefined,
});

/////
export const CO2eModeOfTransportNode1 = atom({
  key: "ModeOfTransportNode1",
  default: undefined,
});

export const CO2eModeOfTransportNode2 = atom({
  key: "ModeOfTransportNode2",
  default: undefined,
});

export const CO2eModeOfTranspotation1 = selector({
  key: "ModeOfTranspotation1",
  get: ({ get }) => String(get(PowderToCustomerDataM)),
  set: ({ set }, newValue) => set(PowderToCustomerDataM, newValue),
});

export const CO2eModeOfTranspotation2 = selector({
  key: "ModeOfTranspotation2",
  get: ({ get }) => String(get(SystemToCustomerDataM)),
  set: ({ set }, newValue) => set(SystemToCustomerDataM, newValue),
});

export const CO2eEnergySMData = atom({
  key: "CO2eEnergySMData",
  default: {
    today: undefined,
    tomorrow: undefined,
  },
});