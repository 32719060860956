import { atom } from "recoil";
export const technologyId = atom({
  key: "technologyId",
  default: undefined,
});
export const stepTypeId = atom({
  key: "stepTypeId",
  default: undefined,
});
export const technologyType = atom({
  key: "technologyType",
  default: undefined,
});
export const MachineImage = atom({
  key: "MachineImage",
  default: undefined,
});
export const overHeadConsumables = atom({
  key: "overHeadConsumables",
  default: 0,
});

export const ProcessFlag = atom({
  key: "ProcessFlag",
  default: {
    MachineDetails: false,
    MachineManufacturing: false,
    PowderManufacturing: false,
    MaterialProcessing: false,
    AMProcess: false,
    Transportation: false,
    InertGas: false,
    ElectricityConsumption: false,
    CarbonContentPP: false,
    CarbonPricing: false,
    PostProcessing: false,
    WastePowder: false,
    ScenarioAnalysis: false,
    Reports: false,
  },
});
export const VisualDonut = atom({
  key: "VisualDonut",
  default: undefined,
});
export const JobsPerWeek = atom({
  key: "JobsPerWeek",
  default: [],
});
export const TotalJobs = atom({
  key: "TotalJobs",
  default: undefined,
});
export const SingleJobTime = atom({
  key: "singlejobtime",
  default: undefined,
});
export const HotspotArray = atom({
  key: "HotspotArray",
  default: [],
});

export const CCMetalworkingWeeksPerYear = atom({
  key: "CCMetalworkingWeeksPerYear",
  default: 46,
});

export const CCPolyworkingWeeksPerYear = atom({
  key: "CCPolyworkingWeeksPerYear",
  default: 46,
});

export const WorkingHours = atom({
  key: "WorkingHours",
  default: false,
});

export const HighestIndexArray = atom({
  key: "HighestIndexArray",
  default: [],
});

export const VisualPageReload = atom({
  key: "VisualPageReload",
  default: false,
});
