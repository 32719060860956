import React from "react";
import {
  PowderAutomizationData,
  VolumePartReductionData,
  Co2ePowderScenarioMData,
  GreenEnergyMixData,
  InertGasReductionSMData,
  PowderAutomizationInput,
  VolumePartReductionInput,
  GreenEnergyMixInput,
  TotalTraspotation,
} from "atoms/Co2Metal/ScenarioMetal";
import { useRecoilValue } from "recoil";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import {
  CO2eModeOfTransportNode1,
  CO2eModeOfTransportNode2,
  CO2eModeOfTranspotation1,
  CO2eModeOfTranspotation2,
} from "atoms/Co2Metal/ScenarioMetal";

const MaterialTableValues = ({ Socket }) => {
  const powderAutomization = useRecoilValue(PowderAutomizationData);
  const volumePartReduction = useRecoilValue(VolumePartReductionData);
  const co2ePowder = useRecoilValue(Co2ePowderScenarioMData);
  const greenEnergyMix = useRecoilValue(GreenEnergyMixData);
  const inertGasReduction = useRecoilValue(InertGasReductionSMData);
  const powderAutomizationInput = useRecoilValue(PowderAutomizationInput);
  const volumePartReductionInput = useRecoilValue(VolumePartReductionInput);
  const greenEnergyMixInput = useRecoilValue(GreenEnergyMixInput);
  const co2eTransportation = useRecoilValue(TotalTraspotation);
  const ModeOfTranspotation1 = useRecoilValue(CO2eModeOfTranspotation1);
  const ModeOfTranspotation2 = useRecoilValue(CO2eModeOfTranspotation2);
  const ModeOfTransportNode1 = useRecoilValue(CO2eModeOfTransportNode1);
  const ModeOfTransportNode2 = useRecoilValue(CO2eModeOfTransportNode2);

  return (
    <div className=" responsive-scroll">
      <div className="divTable">
        <div className="divTableBody">
          <div className="divTableRow">
            {/* <div className="divTableCell1-heading">Unit</div> */}
            <div className="divTableCell1-heading">Input</div>
            <div className="divTableCell1-heading pl-1">Current Scenario</div>
            <div className="divTableCell1-heading">
              <Tippy
                content={
                  <span>
                    Here you can be playful! We have estimated the status quo of
                    your carbon and costs, but let's see how certain changes
                    might impact your values!
                  </span>
                }
              >
                <span> Future Scenario</span>

              </Tippy>
            </div>
          </div>
          {/* <div className="divTableRow">
            <div className="divTableCell1 dropdown-input">
              {powderAutomizationInput
                ? powderAutomizationInput === 'GAS_ATOMIZED' ? 'Gas Atomized' : 'Water Atomized'
                : "Atomization"}
            </div>
            <div className="divTableCell1 pl-3">
              {powderAutomization ? powderAutomization.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {powderAutomization ? powderAutomization.tomorrow : "_ _ _ _"}
            </div>
          </div> TODO commented as of now as per client requirement */}
          <div className="divTableRow">
            {/* <div className="divTableCell1">kg</div> */}
            <div className="divTableCell1 dropdown-input">
              {volumePartReductionInput ? `${volumePartReductionInput}%` : ""}
            </div>
            <div className="divTableCell1 pl-3">
              {volumePartReduction ? volumePartReduction.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {volumePartReduction ? volumePartReduction.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1-special">kg</div> */}
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {co2ePowder ? co2ePowder.today : "_ _ _ _"}
            </div>
            <div className="divTableCell1 pl-3">
              {co2ePowder ? co2ePowder.tomorrow : "_ _ _ _"}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1 p-0">
                            &nbsp;
                        </div> */}
            <div className="divTableCell1 dropdown-input text-capital">
              {greenEnergyMixInput ? greenEnergyMixInput : "choice"}
            </div>
            <div className="divTableCell1 pl-3">
              {greenEnergyMix ? greenEnergyMix.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pl-3">
              {greenEnergyMix ? greenEnergyMix.tomorrow : "_ _ _ "}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
            <div className="divTableCell1-heading p-0">&nbsp;</div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {inertGasReduction ? inertGasReduction.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pl-3">
              {inertGasReduction ? inertGasReduction.tomorrow : "_ _ _ "}
            </div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>
          <div className="divTableRow">
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1">&nbsp;</div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 dropdown-input">
              {ModeOfTranspotation1}
            </div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode1 ? ModeOfTransportNode1.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode1 ? ModeOfTransportNode1.tomorrow : "_ _ _ "}
            </div>
          </div>
          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1 dropdown-input">
              {ModeOfTranspotation2}
            </div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode2 ? ModeOfTransportNode2.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pl-3">
              {ModeOfTransportNode2 ? ModeOfTransportNode2.tomorrow : "_ _ _ "}
            </div>
          </div>

          <div className="divTableRow">
            {/* <div className="divTableCell1">&nbsp;</div> */}
            <div className="divTableCell1">&nbsp;</div>
            <div className="divTableCell1 pl-3">
              {co2eTransportation ? co2eTransportation.today : "_ _ _ "}
            </div>
            <div className="divTableCell1 pl-3">
              {co2eTransportation ? co2eTransportation.tomorrow : "_ _ _ "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialTableValues;
