import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  CCMetalCo2EmissionFactorDataM,
  CCMetalCo2EmissionBuildProcessDataM,
  CCMetalCo2EmissionPowderDataM,
  CCMetalCo2EmissionGasDataM,
  CCMetalCo2EmissionTransportationDataM,
} from "atoms/CCCarbonMetal/CCMetalCarbonContent";

import { CCMetalPowderTypeDataM } from "atoms/CCCarbonMetal/CCMetalPoweredMFG";

import { CCMetalCo2ePerPartPMDataM } from "atoms/CCCarbonMetal/CCMetalPoweredMFG";

import { CCMetalEnergyPerPartDataM } from "atoms/CCCarbonMetal/CCMetalElectricityConsumtion";

import { CCMetalInertGasUsedEnergyDataM } from "atoms/CCCarbonMetal/CCMetalInert";

import { CCMetalCo2ePerPartTMDataM } from "atoms/CCCarbonMetal/CCMetalTransportation";

import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { DebounceInput } from "react-debounce-input";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import TooltipTrippy from "app/components/ProductionScenario/TooltipTrippy";

const CarbonContentPerPart = ({ Socket, staticDataOfMachine }) => {

  const [co2EmissionFactor, setCo2EmissionFactor] = useRecoilState(
    CCMetalCo2EmissionFactorDataM
  );
  const [co2EmissionBP, setCo2EmissionBP] = useRecoilState(
    CCMetalCo2EmissionBuildProcessDataM
  );
  const [co2EmissionPowder, setCo2EmissionPowder] = useRecoilState(
    CCMetalCo2EmissionPowderDataM
  );
  const [co2EmissionGas, setCo2EmissionGas] = useRecoilState(
    CCMetalCo2EmissionGasDataM
  );
  const [co2EmissionTransportation, setCo2EmissionTransportation] =
    useRecoilState(CCMetalCo2EmissionTransportationDataM);
  const [, setProcessFlag] = useRecoilState(ProcessFlag);

  const Co2ePerPartPM = useRecoilValue(CCMetalCo2ePerPartPMDataM);
  const EnergyPerPart = useRecoilValue(CCMetalEnergyPerPartDataM);
  const inertGasUsedEnergy = useRecoilValue(CCMetalInertGasUsedEnergyDataM);
  const co2PerPartTM = useRecoilValue(CCMetalCo2ePerPartTMDataM);
  const AtomizationType = useRecoilValue(CCMetalPowderTypeDataM)
  const { getComputedValue } = useRoundOffValue();


  useEffect(() => {
    Socket.off("*");

    Socket.emit("carbon-calculator", { key: "DBCC-CCPPCEF", input: "" });

    Socket.on("DBCC-CCPPCEF", function (data) {

      const value = getComputedValue(data?.data?.value);
      //CHECKED
      setCo2EmissionFactor(value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (co2PerPartTM !== undefined) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCET",
        input: { co2e_per_part_tm: co2PerPartTM },
      });

      Socket.on("FOCC-CCPPCET", function (data) {
        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionTransportation(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2PerPartTM]);

  useEffect(() => {
    if (co2EmissionFactor && Co2ePerPartPM && staticDataOfMachine?.material_id && AtomizationType) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEP",
        input: {
          co2_emission_factor: co2EmissionFactor,
          co2e_per_part: Co2ePerPartPM,
          material_id: staticDataOfMachine?.material_id?.value,
          automization_type: AtomizationType
        },
      });

      Socket.on("FOCC-CCPPCEP", function (data) {

        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionPowder(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EmissionFactor, Co2ePerPartPM, staticDataOfMachine?.material_id, AtomizationType]);

  useEffect(() => {
    if (
      inertGasUsedEnergy !== undefined &&
      staticDataOfMachine.no_of_parts !== undefined &&
      co2EmissionFactor !== undefined
    ) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEG",
        input: {
          inert_gas_used_energy: +inertGasUsedEnergy,
          co2_emission_factor: +co2EmissionFactor,
          no_of_parts: parseInt(staticDataOfMachine.no_of_parts.value),
          energy_per_part: EnergyPerPart,
        },
      });

      Socket.on("FOCC-CCPPCEG", function (data) {

        const value = getComputedValue(data?.data?.value);
        //CHECKED
        setCo2EmissionGas(value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inertGasUsedEnergy, co2EmissionFactor]);


  useEffect(() => {
    if (co2EmissionFactor && EnergyPerPart) {
      Socket.off("*");


      Socket.emit("carbon-calculator", {
        key: "FOCC-CCPPCEBP",
        input: {
          co2_emission_factor: co2EmissionFactor,
          energy_per_part: EnergyPerPart,
        },
      });

      Socket.on("FOCC-CCPPCEBP", function (data) {

        const value = getComputedValue(data?.data?.value);

        setCo2EmissionBP(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2EmissionFactor, EnergyPerPart]);

  useEffect(() => {
    if (
      co2EmissionFactor !== null &&
      co2EmissionBP !== null &&
      co2EmissionPowder !== null &&
      co2EmissionGas !== null &&
      co2EmissionTransportation !== null
    ) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          CarbonContentPP: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    co2EmissionFactor,
    co2EmissionBP,
    co2EmissionPowder,
    co2EmissionGas,
    co2EmissionTransportation,
  ]);

  return (
    <>
      <p className="input-heading">
        <TooltipTrippy fieldName="CO2e emission factor (g/kWh)" tooltipMessage="Details on factors can be found under Data matrix tab" />
      </p>
      {co2EmissionFactor !== undefined || co2EmissionFactor !== null ? (
        <div className="input-section">
          <DebounceInput
            type="number"
            className="user-input"
            debounceTimeout={500}
            onChange={(e) => {
              setCo2EmissionFactor(e.target.value);
            }}
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            placeholder="Please enter CO2e per job"
            value={co2EmissionFactor}
          />
          <OverrideAbleTip />
        </div>
      ) : (
        <div className="data-field">
          <Spinner animation="border" size="sm" />
        </div>
      )}

      {
      // TODO Co2e emission build process not being shown now 
      /* <p className="input-heading">CO2e emission build process (kg)</p>
      <div className="data-field">
        {co2EmissionBP !== undefined || co2EmissionBP !== null ? (
          co2EmissionBP
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div> */}

      <p className="input-heading">CO2e powder (kg)</p>
      <div className="data-field">
        {" "}
        {co2EmissionPowder !== undefined || co2EmissionPowder !== null ? (
          co2EmissionPowder
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>

      <p className="input-heading">CO2e energy (kg)</p>
      <div className="data-field">
        {co2EmissionGas !== undefined || co2EmissionGas !== null ? (
          co2EmissionGas
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>

      <p className="input-heading">CO2e logistics (kg)</p>

      <div className="data-field">
        {co2EmissionTransportation !== undefined ||
          co2EmissionTransportation !== null ? (
          co2EmissionTransportation
        ) : (
          <Spinner animation="border" size="sm" />
        )}
      </div>
    </>
  );
};

export default CarbonContentPerPart;
