import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useRecoilState } from "recoil";
import {
  CCMetalCo2ePerJobPMDataM,
  CCMetalCo2ePerPartPMDataM,
  CCMetalPowderTypeDataM,
} from "atoms/CCCarbonMetal/CCMetalPoweredMFG";
import { useRoundOffValue } from "app/components/hooks/useRoundOffValue";
import { ProcessFlag } from "atoms/General";
import OverrideAbleTip from "app/components/ProductionScenario/OverrideAbleTip";
import { DebounceInput } from 'react-debounce-input';

const PowderMfg = ({ Socket, staticDataOfMachine }) => {
  const [co2epj, setCo2epj] = useRecoilState(
    CCMetalCo2ePerJobPMDataM
  );
  const [co2epp, setCo2epp] = useRecoilState(
    CCMetalCo2ePerPartPMDataM
  );
  const [powdertype, setPowderType] = useRecoilState(CCMetalPowderTypeDataM);
  const [, setProcessFlag] = useRecoilState(ProcessFlag);
  const { getComputedValue } = useRoundOffValue();

  useEffect(() => {
    if (
      staticDataOfMachine.total_part_volume !== undefined &&
      staticDataOfMachine.material_density !== undefined &&
      staticDataOfMachine.additional_material_losses !== undefined &&
      staticDataOfMachine.material_id !== undefined &&
      powdertype !== undefined
    ) {
      Socket.off("*");

      Socket.emit("carbon-calculator", {
        key: "FOCC-PMPEPJ",
        input: {
          total_part_volume: staticDataOfMachine.total_part_volume.value,
          material_density: staticDataOfMachine.material_density.value,
          additional_material_losses:
            staticDataOfMachine.additional_material_losses.value,
          atomized_type: powdertype,
          material_id: staticDataOfMachine.material_id.value,
        },
      });

      Socket.on("FOCC-PMPEPJ", function (data) {
        const value = getComputedValue(data?.data?.value);

        setCo2epj(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powdertype]);



  useEffect(() => {
    if (
      staticDataOfMachine.no_of_parts !== undefined &&
      co2epj !== undefined
    ) {
      Socket.off("*");
      Socket.emit("carbon-calculator", {
        key: "FOCC-PMPEPP",
        input: {
          no_of_parts: staticDataOfMachine.no_of_parts.value,
          co2e_per_job: co2epj,
        },
      });

      Socket.on("FOCC-PMPEPP", function (data) {
        const value = getComputedValue(data?.data?.value);

        setCo2epp(value);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [co2epj]);

  useEffect(() => {
    if (powdertype !== null && co2epj !== null && co2epp !== null) {
      setProcessFlag((prevState) => {
        return {
          ...prevState,
          PowderManufacturing: true,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powdertype, co2epj, co2epp]);

  return (
    <>
      <p className="input-heading">Atomization Type</p>
      <div className="data-field">
        gas atomized
        {/*  value to be displayed for powdertype */}
      </div>
      <p className="input-heading">CO2e per job (Kgs)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epj(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={co2epj !== undefined || co2epj !== null ? (
            co2epj
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        />
        <OverrideAbleTip />
      </div>
      <p className="input-heading">CO2e per part (kgs)</p>
      <div className="input-section">
        <DebounceInput
          type="number"
          className="user-input"
          debounceTimeout={500}
          onChange={(e) => {
            setCo2epp(e.target.value);
          }}
          onWheel={(e) => e.target.blur()}
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          value={co2epp !== undefined || co2epp !== null ? (
            co2epp
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        />
        <OverrideAbleTip />
      </div>
    </>
  );
};

export default PowderMfg;
