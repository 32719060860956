import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateEmail, validatePassword } from "utils/validations.js";
import { useHistory } from "react-router-dom";
import { UserStates } from '../../utils/react-redux/actions/user_states';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useSetAccess } from "state/hooks.js";
import { BsFillCheckCircleFill, BsXCircleFill } from 'react-icons/bs'

const SIGNUP_USER = gql`
    mutation clientSignUp($email: String! , $password: String! , $is_premium : Boolean ){
      clientSignUp(email:$email, password:$password , is_premium : $is_premium){
        access_token
        user_info {
          user_id
          email
          is_premium
          grant_at
          is_terms_condition
          group_id {
            group_id
            name
            description
          }
        }
      }
    }
`;
const GET_REGIONS = gql`
  query GetRegionsByUserGroup($group_id : Int!){
    getRegionsByUserGroup(group_id : $group_id ){
        name
        region_id
        exchange_rate
        currency_symbol
      }
      
  }
`;

const SignUp = () => {
  const history = useHistory();
  const client = useApolloClient();
  const [loadpage, setLoadPage] = useState(false);
  const [toggleSignupMutation] = useMutation(SIGNUP_USER);
  const { setAccessState } = useSetAccess()

  let [regionNamesWithCurrency] = useState([]);
  const isLogged = useSelector((state) => state.signIn)
  const dispatch = useDispatch()

  const [signupState, setSignupState] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    is_premium: false,
    isLoading: false,
  });
  const validateEmailText = (email, data = {}) => {
    if (validateEmail(email)) {
      setSignupState({
        ...signupState,
        email,
        emailError: "",
        ...data,
      });
      return true;
    } else {
      setSignupState({
        ...signupState,
        email,
        emailError: "Invalid email",
        ...data,
      });
      return false;
    }
  };
  const validatePasswordText = (password, data = {}) => {
    if (validatePassword(password)) {
      setSignupState({
        ...signupState,
        password,
        passwordError: "",
        ...data,
      });
      return true;
    } else {
      setSignupState({
        ...signupState,
        password,
        passwordError: "Length must be between[8-30]",
        ...data,
      });
      return false;
    }
  };

  const validateConfirmPasswordText = (confirmPassword, data = {}) => {
    if (validatePassword(confirmPassword)) {
      setSignupState({
        ...signupState,
        confirmPassword,
        confirmPasswordError: "",
        ...data,
      });
      return true;
    } else {
      setSignupState({
        ...signupState,
        confirmPassword,
        confirmPasswordError: "Length must be between[8-30]",
        ...data,
      });
      return false;
    }
  };
  const validateMatchPasswordsText = () => {
    if (signupState.password !== signupState.confirmPassword) {
      setSignupState({
        ...signupState,
        confirmPasswordError: "Password don't match",
      });
      return false;
    } else {
      setSignupState({
        ...signupState,
        confirmPasswordError: "",
      });
      return true;
    }
  };
  const onPressSignup = async () => {
    if (
      !validateEmailText(signupState.email) ||
      !validatePasswordText(signupState.password) ||
      !validateConfirmPasswordText(signupState.confirmPassword) ||
      !validateMatchPasswordsText()
    ) {
      return;
    }
    setSignupState({
      ...signupState,
      isLoading: true,
    });
    await toggleSignupMutation({
      variables: {
        email: signupState.email,
        password: signupState.password,
        is_premium: signupState.is_premium,
      },
      optimisticResponse: true,
    }).then(res => {
      setAccessState(true)
      sessionStorage.setItem('allow', 'fix');
      sessionStorage.setItem('access_token', res.data.clientSignUp.access_token);
      sessionStorage.setItem('accept-agreement', res.data.clientSignUp.user_info.is_terms_condition);
      sessionStorage.setItem('user_id', res.data.clientSignUp.user_info.user_id);
      //get regions
      client.query({
        query: GET_REGIONS,
        variables: { group_id: res.data.clientSignUp.user_info.group_id.group_id },
        fetchPolicy: 'no-cache'
      }).then(res => {
        res.data.getRegionsByUserGroup.map((temp) => {
          regionNamesWithCurrency.push(temp.name + ' (' + temp.currency_symbol + ')')
          return temp.name;
        });
        const uniqueNames = Array.from(new Set(regionNamesWithCurrency));
        sessionStorage.setItem("CurrencyRegions", JSON.stringify(uniqueNames));
        sessionStorage.setItem("all_currencies", JSON.stringify(res.data.getRegionsByUserGroup));
        setLoadPage(true);

        setSignupState({
          ...signupState,
          isLoading: false,
        });

      }).catch(error => {
        // toast.error(error.graphQLErrors[0].messasge.message)
      });

      sessionStorage.setItem('group_id', res.data.clientSignUp.user_info.group_id.group_id);
      if (isLogged === false) {
        dispatch(UserStates())
      }
      sessionStorage.setItem('signInstatus', 'true');
      sessionStorage.setItem('signIn', 'true');
      setSignupState({
        ...signupState,
        isLoading: false,
      });
    }).catch(error => {
      const {
        graphQLErrors,
        networkError
      } = error;

      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          const { exception } = extensions;
          if (exception.message && typeof exception.message !== 'string') {
            toast(exception.message[0]);
          } else {
            toast(exception.message);
          }
        }
        );
      } else if (networkError) {
        toast(`[Network error]: ${networkError}`);
      }
      //false loading
      setSignupState({
        ...signupState,
        isLoading: false,
      });
    });
  };
  const nextKey = (key) => {
    document.getElementById(key).focus();
  }

  React.useEffect(() => {
    if (loadpage) {
      window.location.href = `/region-select`
    }
  }, [loadpage]);


  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row fill">
        <div className="col-lg-12  login-container">
          <div className="box-wrapper">
     

            <table className="table-signup ">
              <thead>
                <tr>
                  <th></th>
                  <th>Freemium</th>
                  <th>Premium Yearly</th>
                  <th>Premium Lifetime</th>
                </tr>
              </thead>
              <tbody>
                <tr className="bold">
                  <td>Access</td>
                  <td>One Machine + One Material and <br /> only Estimation Cost</td>
                  <td >Access all Machines and Material <br /> with estimation as well as simulation</td>
                  <td>Access all Machines and Material <br /> with estimation as well as simulation</td>
                </tr>
                <tr>
                  <td className="bold">Consulting Support</td>
                  <td><BsXCircleFill className="red-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                </tr>
                <tr>
                  <td className="bold">Scenario Analysis</td>
                  <td><BsXCircleFill className="red-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                </tr>
                <tr>
                  <td className="bold">Sensitivity Analysis</td>
                  <td><BsXCircleFill className="red-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                </tr>
                <tr>
                  <td className="bold">Report Generation</td>
                  <td><BsXCircleFill className="red-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                </tr>
                <tr>
                  <td className="bold">Access to customize cases for customer pricing</td>
                  <td><BsXCircleFill className="red-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                  <td><BsFillCheckCircleFill className="green-cl" /></td>
                </tr>
                <tr>
                  <td className="bold">Cost</td>
                  <td>0 Euros</td>
                  <td>150 Euros/Year</td>
                  <td>250 Euros - One time fee <br /><small>Possible with Machine sales</small></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <div className={'type-btn deadcenter type-selected'} onClick={() => {
                      setSignupState({
                        ...signupState,
                        is_premium: false
                      });
                    }}>
                      <p>Freemium</p>
                    </div></td>
                  <td colSpan={2}>
                    <div className={'type-btn  deadcenter '} onClick={() => {
                      setSignupState({
                        ...signupState,
                        is_premium: true
                      });
                    }}>
                      <p>Premium</p>
                    </div></td>
                </tr>
              </tbody>
            </table>
            <div className="dd-flex">
              <div className="pr-3">
                <p className="signup-text">Email</p>
                <div className="text-box1">
                  <input type="email" id="email-value" className="login-input " value={signupState.email} onChange={(evt) => {
                    validateEmailText(evt.target.value);
                  }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        nextKey('pass-value');
                      }
                    }}
                  />
                </div>
                {signupState.emailError && (
                  <p className="errorMessage float-left">{signupState.emailError}</p>
                )}
              </div>
              <div className="pr-3">
                <p className="signup-text">Password</p>
                <div className="text-box1">
                  <input type="password" id="pass-value" className="login-input" value={signupState.password} onChange={(evt) => {
                    validatePasswordText(evt.target.value);

                  }}

                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        nextKey('confirm-value');
                      }
                    }} />
                </div>
                {signupState.passwordError && (
                  <p className="errorMessage float-left">{signupState.passwordError}</p>
                )}
              </div>
              <div className="pr-3">

                <p className="signup-text">Confirm Password</p>
                <div className="text-box1">
                  <input type="password" id="confirm-value" className="login-input" value={signupState.confirmPassword} onChange={(evt) => {
                    validateConfirmPasswordText(evt.target.value);
                  }}

                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        onPressSignup();
                      }
                    }} />
                </div>
                {signupState.confirmPasswordError && (
                  <p className="errorMessage float-left">{signupState.confirmPasswordError}</p>
                )}
              </div>
              <div className="pr-3">
                <div className="signup-text"><a href="/">Already have an account <strong className="sign-up-text">Sign In</strong></a></div>
                <button className="button1 margin-dec" onClick={onPressSignup} disabled={Boolean(signupState.isLoading)}>
                  {signupState.isLoading ? <><i className="fa fa-spinner fa-spin mr-2"></i><p>Loading</p></> : <p>Sign Up</p>}
                </button>
              </div>
              {/* <div className={"" + (!signupState.confirmPassword && !signupState.emailError && !signupState.passwordError? "mt-auto"  : "margin-adject" )}>
                <p className="signup-text"></p>
                <button className="button1 margin-dec" onClick={onPressSignup} disabled={Boolean(signupState.isLoading)}>
                  {signupState.isLoading ? <><i className="fa fa-spinner fa-spin mr-2"></i><p>Loading</p></> : <p>Sign Up</p>}
                </button>
              </div> */}

            </div>

            {/* <div className=".subtext2 mt-3"><a href="/">Already Having Account <strong className="sign-up-text">Sign In</strong></a></div> */}

          </div>
        </div>
        {/* <div className="col-lg-6 coverimage image1"></div> */}
      </div>
    </React.Fragment>

  )
}

export default SignUp;