import React, { useState } from "react";
import Navbar from "../components/Nav.jsx";
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
// import { UserStates } from '../../src/utils/react-redux/actions/user_states';
import { UserStates } from '../../utils/react-redux/actions/user_states';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSetAccess } from "state/hooks.js";

const AUTH_USER = gql`
    query login($email: String! , $password: String! ){
        clientLogin(email:$email, password:$password){
            access_token
            user_info {
              user_id
              email
              is_premium
              grant_at
              is_terms_condition
              group_id {
                group_id
                name
                description
              }
            }
        }
    }
`;
const GET_REGIONS = gql`
  query GetRegionsByUserGroup($group_id : Int!){
    getRegionsByUserGroup(group_id : $group_id ){
        name
        region_id
        exchange_rate
        currency_symbol
      }
      
  }
`;
const Login = () => {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const emailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
  const [Loading, setLoading] = useState(false);
  const [loadpage, setLoadPage] = useState(false);
  const [email, setEmail] = useState(''); // '' is the initial state for email value
  const [password, setPassword] = useState(''); // '' is the initial state for password value
  const client = useApolloClient();
  const isLogged = useSelector((state) => state.signIn)
  const dispatch = useDispatch()
  let [regionNamesWithCurrency] = useState([]);
  const { setAccessState } = useSetAccess()

  const validate = () => {
    let passwordError = "";
    let emailError = "";
    if (email.length === 0) {
      emailError = "Email is required"
    } else if (emailRegex.test(email) !== true) {
      emailError = "Email Is Invalid"
    } else {
      emailError = "";
    }
    if (password.length === 0) {
      passwordError = "Password is required";
    } else if (password.length < 8 || password.length > 30) {
      passwordError = "Length must be between[8-30]"
    } else {
      passwordError = "";
    }

    if (emailError || passwordError) {
      setEmailError(emailError);
      setPasswordError(passwordError);
      return false;
    } else {
      return true;
    }
  }
  //Login user 
  const LoginUser = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      setEmailError("");
      setPasswordError("");
      await client.query({
        query: AUTH_USER,
        variables: { email, password }
      }).then(res => {

        // debugger
        if (res.data.clientLogin.user_info.is_premium) {
          setAccessState(true)
          sessionStorage.setItem('allow', 'flexible')
        } else {
          setAccessState(true)
          sessionStorage.setItem('allow', 'fix')
        }
        toast.success('Login Successfully')
        sessionStorage.setItem('access_token', res.data.clientLogin.access_token);
        sessionStorage.setItem('accept-agreement', res.data.clientLogin.user_info.is_terms_condition);
        sessionStorage.setItem('user_id', res.data.clientLogin.user_info.user_id);
        //get regions
        client.query({
          query: GET_REGIONS,
          variables: { group_id: res.data.clientLogin.user_info.group_id.group_id },
          fetchPolicy: 'no-cache'
        }).then(res => {
          res.data.getRegionsByUserGroup.map((temp) => {
            regionNamesWithCurrency.push(temp.name + ' (' + temp.currency_symbol + ')')
            return temp.name;
          });
          const uniqueNames = Array.from(new Set(regionNamesWithCurrency));
          sessionStorage.setItem("CurrencyRegions", JSON.stringify(uniqueNames));
          sessionStorage.setItem("all_currencies", JSON.stringify(res.data.getRegionsByUserGroup));
          setLoadPage(true);
          setLoading(false);

        }).catch(error => {
          // toast.error(error.graphQLErrors[0].messasge.message)
        });

        setEmail('');
        setPassword('');
        sessionStorage.setItem('group_id', res.data.clientLogin.user_info.group_id.group_id);
        if (isLogged === false) {
          dispatch(UserStates())
        }
        sessionStorage.setItem('signInstatus', 'true');
        sessionStorage.setItem('signIn', 'true');

      }).catch(error => {
        setLoading(false);
        toast.error(error.graphQLErrors[0].extensions.exception.response.error)
        if (error.graphQLErrors[0].extensions.exception.response.error === 'Incorrect Password') {
          setPassword('');
        } else {
          setEmail('');
          setPassword('');
        }
      });
    }

  }
  const nextKey = () => {
    document.getElementById('pass-value').focus();
  }
  React.useEffect(() => {
    if (loadpage) {
      window.location.href = `/region-select`
    }
  }, [loadpage]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Navbar />
      <div className="row fill">
        <div className="col-lg-6 login-container">
          <div>
            <p className="login-text">EMAIL</p>
            <div className="text-box1">
              <input type="email" id="email-value" className="login-input " value={email} onChange={(evt) => {
                setEmailError('')
                setEmail(evt.target.value);
              }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    nextKey();
                  }
                }}
              />
            </div>
            <p className="errorMessage float-left">{emailError}</p>
            <p className="login-text">PASSWORD</p>
            <div className="text-box1">
              <input type="password" id="pass-value" className="login-input" value={password} onChange={(evt) => {
                setPasswordError('')
                setPassword(evt.target.value);
              }}

                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    LoginUser();
                  }
                }} />
            </div>
            <span className="errorMessage float-left">{passwordError}</span>
            <div className="subtext1"><a href="/reset-password">Account Recovery</a></div>
            <button className="button1" onClick={LoginUser} disabled={Boolean(Loading)}>
              {Loading ? <><i class="fa fa-spinner fa-spin mr-2"></i><p>Loading</p></> : <p>Login</p>}
            </button>
            <div className="subtext1 bold mt-2"><a href="/sign-up">Don’t have an account please <strong className="sign-up-text">Sign Up</strong></a></div>
          </div>
        </div>
        <div className="col-lg-6 coverimage image1"></div>
      </div>
    </React.Fragment>

  )
}

export default Login;