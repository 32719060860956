import { CostData } from "atoms/CostToCarbonData";
import React, { useEffect, useState } from "react";
import ProductionScenario from "app/components/ProductionScenario";
import config from "config";

import ThirdReport from "./ThirdReport";
import { GET_CLIENT_EMAILS } from "app/view/CCStepsMetal/components/ProcessMetal/components/getStepDetail";
import { useApolloClient } from "@apollo/react-hooks";
import { useRecoilValue, useRecoilState } from "recoil";
import { EditText } from "react-edit-text";
import { CCMetalCostOfCo2PerPartFDataM } from "atoms/CCCarbonMetal/CCMetalPricing";
import {
  VisualDonut,
  JobsPerWeek,
  TotalJobs,
  SingleJobTime,
  MachineImage,
  CCMetalworkingWeeksPerYear,
  HotspotArray,
  HighestIndexArray,
  WorkingHours
} from "atoms/General/index.js";
import { useGetMachineImage } from "../../../CCPolymerSteps/components/CurrentReports/components/getMachineImage";
import * as htmlToImage from "html-to-image";
import Co2InReports from "app/components/Co2InCostReports/Co2InReports";
import HotspotTable from "app/view/HotspotTable";
import ConsultingTeam from "app/components/ConsultingTeam";

const axios = require("axios").default;
const SecondReport = ({
  staticDataOfMachine,
  PreviousScenarioState,
  isWorkingPerJobEditable,
  ScenarioState,
  isHotSpotAvailable
}) => {
  const client = useApolloClient();
  const [totalJobs, setTotalJobs] = useRecoilState(TotalJobs);
  const [machineImage, setMachineimage] = useRecoilState(MachineImage);

  const groupid = parseInt(sessionStorage.getItem("group_id"));
  const [clientSteps, setClientSteps] = useState([]);
  const region = sessionStorage.getItem("currency");
  const [email, setEmail] = useState();
  const [cppData, setCppData] = useState([]);
  const [cppSum, setcppSum] = useState(0);

  const [singleJobTime, setSingleJobTime] = useRecoilState(SingleJobTime);
  const [hotspotArray, setHotSpotArray] = useRecoilState(HotspotArray)

  const costOfCo2pp = useRecoilValue(CCMetalCostOfCo2PerPartFDataM);
  const visualDonut = useRecoilValue(VisualDonut);
  const jobsPerWeek = useRecoilValue(JobsPerWeek);
  const highestIndexArray = useRecoilValue(HighestIndexArray)
  const workingHours = useRecoilValue(WorkingHours)

  const [psData, setpsData] = useState();
  const [psImage, setPsImage] = useState();

  const [workingWeeksPerYear, setWorkingWeeksPerYear] = useRecoilState(
    CCMetalworkingWeeksPerYear
  );
  const jobYear = workingWeeksPerYear * totalJobs;
  const d = useGetMachineImage(staticDataOfMachine?.machine_id?.value);

  setMachineimage(d);
  const pageSize = 3
  const access_token = sessionStorage.getItem("access_token");
  let hightlightKey = ""
  highestIndexArray.forEach(item => hightlightKey = Object.keys(item).reduce((prevValue, currValue) => item[prevValue] > item[currValue] ? prevValue : currValue))

  const getHotSpotAnalysis = async (pageNo) => {
    try {
      const response = await axios.default.get(`${config.serviceUrl}hotspot/analysis/user/list`, {
        params: {
          pageSize: pageSize,
          pageNo: pageNo,
          technology_id: 1
        },
        headers: {
          Authorization: access_token,

        }
      })

      setHotSpotArray(response.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setpsData({
      type: "Metal",
      setupTime: +staticDataOfMachine?.setup_time?.value,
      buildTime: +staticDataOfMachine?.build_time?.value,
      unpackingtime: +staticDataOfMachine?.unpacking_time_per_job?.value,
    });
    setTimeout(() => {
      var node = document.getElementById("productionschedule");

      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          setPsImage(dataUrl);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }, 2000);
    const data = Object.fromEntries(
      Object.entries(staticDataOfMachine).filter(([key]) =>
        key.includes("cpp_part_")
      )
    );
    const dataArray = Object.values(data);
    setCppData(dataArray);
    for (let index = 0; index < dataArray.length; index++) {

      setcppSum(
        (value) => {
          return (value + +dataArray[index].value)
        }
      )
    }
    getHotSpotAnalysis(0)

  }, []);
  useEffect(() => {
    client
      .query({
        query: GET_CLIENT_EMAILS,
        variables: { group_id: groupid },
      })
      .then(({ data, loading }) => {
        setClientSteps(data.getRegionsByUserGroup);
      })
      .catch((error) => {
        console.log("error in process", error);
        // toast.error(error.graphQLErrors[0].messasge.message)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (let index = 0; index < clientSteps.length; index++) {
      if (clientSteps[index].name === region) {
        setEmail(clientSteps[index].email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSteps]);
  // Getting Currency Symbol...
  const currencies = JSON.parse(sessionStorage.getItem("all_currencies"));
  const currency = sessionStorage.getItem("currency");
  const obj = currencies.find((x) => x.name === currency);

  const getjobsWeek = (jobs) => {
    setTotalJobs(jobs);
  };
  const getTurnAroundTime = (time) => {
    setSingleJobTime(time);
  };
  return (
    <div className="reports-section">
      {/* Cost per Part */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Cost Per Part </p>
          <p className="powder-heading text-align-end">
            {
              staticDataOfMachine.cost_per_part ? `${staticDataOfMachine.cost_per_part?.value} ${obj.currency_symbol}` : ""
            }
          </p>
        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">System cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.system_cost_per_part?.value ? (
                <>{`${staticDataOfMachine?.system_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Material cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.material_cost_per_part?.value ? (
                <>{`${staticDataOfMachine?.material_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Post processing cost per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.post_processing_cost_per_part?.value ? (
                <>{`${staticDataOfMachine?.post_processing_cost_per_part?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Overhead and consumables per part</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.overhead_and_consumables?.value ? (
                <>{`${staticDataOfMachine?.overhead_and_consumables?.value} ${obj.currency_symbol}`}</>
              ) : (
                "Not Available"
              )}
            </p>
          </span>
        </div>

        {
          (costOfCo2pp && costOfCo2pp !== 0) ?
            <>
              <Co2InReports costOfCo2pp={costOfCo2pp} currency={obj.currency_symbol} />

            </> : ''
        }

      </div>

      {staticDataOfMachine?.multi_part?.value !== undefined &&
        staticDataOfMachine?.multi_part?.value > 0 && (
          <>
            <div className="powder-container">
              <div className="powder-heading-top head-padding">
                <p className="powder-heading">Multiple Parts</p>
              </div>
              <div className="d-flex justify-content-between pl-4 pr-4">
                <span>
                  <p className="input-heading">Part volume</p>
                </span>
                <span>
                  <p className="output-heading">
                    {staticDataOfMachine?.part_volume?.value ? (
                      <>
                        {`${staticDataOfMachine?.part_volume?.value} cm`}
                        <sup>3</sup>
                      </>
                    ) : (
                      "Not Available"
                    )}
                  </p>
                </span>
              </div>
              <i className="pl-4 pr-4">
                <hr />
              </i>
              <div className="d-flex justify-content-between pl-4 pr-4">
                <span>
                  <p className="input-heading">Part support volume</p>
                </span>
                <span>
                  <p className="output-heading">
                    {staticDataOfMachine?.part_support_volume?.value ? (
                      <>
                        {`${staticDataOfMachine?.part_support_volume?.value} cm`}
                        <sup>3</sup>
                      </>
                    ) : (
                      "Not Available"
                    )}
                  </p>
                </span>
              </div>
              <i className="pl-4 pr-4">
                <hr />
              </i>
              <div className="d-flex justify-content-between pl-4 pr-4">
                <span>
                  <p className="input-heading">Number of parts</p>
                </span>
                <span>
                  <p className="output-heading">
                    {staticDataOfMachine?.no_of_parts?.value ? (
                      <>{`${staticDataOfMachine?.no_of_parts?.value}`}</>
                    ) : (
                      "Not Available"
                    )}
                  </p>
                </span>
              </div>
            </div>
          </>
        )}
      {/* CPP PART ID EXISTS */}
      {cppData !== undefined && cppData.length > 1 && (
        <div className="powder-container">
          <div className="powder-heading-top head-padding">
            <p className="powder-heading">Multiple Parts</p>
          </div>
          {cppData !== undefined &&
            cppData.map((data, index) => {
              return (
                <>
                  <div
                    className="d-flex justify-content-between pl-4 pr-4"
                    key={index}
                  >
                    <span>
                      <p className="input-heading">{`Cost per part ${index + 1
                        }`}</p>
                    </span>
                    <span>
                      <p className="output-heading">{data.value}</p>
                    </span>
                  </div>
                  <i className="pl-4 pr-4">
                    <hr />
                  </i>
                </>
              );
            })}
        </div>
      )}
      {/* Production Scenarios */}
      <div className="powder-container">
        <div className="powder-heading-top head-padding">
          <p className="powder-heading">Production Scenarios</p>
        </div>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Builds per week</p>
          </span>
          <span>
            <p className="output-heading">
              {totalJobs == null ? "Not Availbel" : totalJobs}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Working weeks per year</p>
          </span>
          {isWorkingPerJobEditable === null ? (
            <span>
              <p className="output-heading">
                <EditText
                  name="working-weeks-per-year"
                  style={{ fontSize: "16px", border: "1px solid #ccc" }}
                  value={workingWeeksPerYear}
                  onChange={(event) =>
                    setWorkingWeeksPerYear(event.target.value)
                  }
                />
              </p>
            </span>
          ) : (
            <span>
              <p className="output-heading">
                {workingWeeksPerYear == null
                  ? "Not Available"
                  : workingWeeksPerYear}
              </p>{" "}
            </span>
          )}
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Jobs per year</p>
          </span>
          <span>
            <p className="output-heading">
              {jobYear == null ? "Not Available" : Math.round(jobYear)}
            </p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Throughput per year</p>
          </span>
          <span>
            <p className="output-heading">
              {Math.trunc(+(jobYear * +staticDataOfMachine?.no_of_parts?.value))}
            </p>
          </span>
        </div>
        <i className="pl-4 pr-4">
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Turnaround time</p>
          </span>
          <span>
            <p className="output-heading">{`${singleJobTime} h`}</p>{" "}
          </span>
        </div>

        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>

        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Operators needed per machine</p>
          </span>
          <span>
            <p className="output-heading">
              {staticDataOfMachine?.number_of_operators?.value ? (
                <>{`${staticDataOfMachine?.number_of_operators?.value}`}</>
              ) : (
                "Not Available"
              )}</p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between pl-4 pr-4">
          <span>
            <p className="input-heading">Number of shifts</p>
          </span>
          <span>
            <p className="output-heading">{
              workingHours === "true" ? 1 : 3
            }</p>{" "}
          </span>
        </div>
        <i className="pl-4 pr-4">
          {" "}
          <hr />
        </i>
        <div className="d-flex justify-content-between px-4">
          <p className="input-heading">
            Operator’s time needed per job
          </p>
          <p className="output-heading">{+staticDataOfMachine?.unpacking_time_per_job?.value + +staticDataOfMachine?.setup_time?.value} h</p>{" "}
        </div>
      </div>

      <h2 className="mt-5">Weekly Production Scheduling Scenario</h2>

      <ProductionScenario
        getjobsWeek={getjobsWeek}
        staticData={psData}
        setStaticData={setpsData}
        getTurnAroundTime={getTurnAroundTime}
        type="Metal"
      />

      {
        isHotSpotAvailable ? '' : <HotspotTable hotspotArray={hotspotArray} hightlightKey={hightlightKey} />
      }



      {/* Pagination */}
      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => PreviousScenarioState()}
          className="selection-button step-button"
        >
          Previous
        </button>
        <ThirdReport
          cppData={cppData}
          staticDataOfMachine={staticDataOfMachine}
          currency={obj.currency_symbol}
          costOfCo2pp={costOfCo2pp}
          visualDonut={visualDonut}
          jobsPerWeek={jobsPerWeek}
          totalJobs={totalJobs}
          singleJobTime={singleJobTime}
          machineImage={machineImage}
          psImage={psImage}
          cppSum={cppSum}
          hotspotArray={hotspotArray}
          hightlightKey={hightlightKey}
          isHotSpotAvailable={isHotSpotAvailable}

        />
        <div className="d-flex justify-content-center align-items-center">
          <ConsultingTeam navigate={email} />
        </div>
      </div>
    </div>
  );
};

export default SecondReport;
