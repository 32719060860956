import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import MaterialTable from "../ReportsMetal/components/MaterialTable";
import MaterialTableValues from "./components/MaterialTableValues";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  Co2TotalTodaySMData,
  Co2TotalTomorrowSMData,
  PowderAutomizationData,
  VolumePartReductionData,
  Co2ePowderScenarioMData,
  GreenEnergyMixData,
  InertGasReductionSMData,
  PowderAutomizationInput,
  VolumePartReductionInput,
  GreenEnergyMixInput,
  TotalTraspotation,

} from "atoms/Co2Metal/ScenarioMetal";
import { Co2ePerPartTMDataM } from "atoms/Co2Metal/TransportationAtoms";
import {
  technologyId,
  stepTypeId,
  technologyType,
} from "atoms/General/index.js";
import {
  MachineDataM,
  MaterialDataM,
  PartVolumeDataM,
  PartSupportVolumeDataM,
} from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  MachineNameDataM,
  MaterialNameDataM,
} from "atoms/Co2Metal/MachineDetailsAtoms";
import {
  CO2eModeOfTranspotation1,
  CO2eModeOfTranspotation2,
} from "atoms/Co2Metal/ScenarioMetal";
import {
  MaterialTransportationDataM,
  SystemTransportationDataM,
  CarbonEmissionFactorNode1DataM,
  CarbonEmissionFactorNode2DataM,
} from "atoms/Co2Metal/TransportationAtoms";
import { BuildTimeDataM } from "atoms/Co2Metal/MachineDetailsAtoms";
import { Co2EmissionPowderDataM, Co2EmissionBuildProcessDataM, Co2EmissionGasDataM, } from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { Co2ePerPartPMDataM } from "atoms/Co2Metal/PowderMfgAtoms";
import { Co2EmissionFactorDataM } from "atoms/Co2Metal/CarbonContentPerPartAtoms";
import { NoOfPartsDataM } from "atoms/Co2Metal/MachineDetailsAtoms";

import config from "../../../../../config";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { ProcessFlag } from "atoms/General";

const axios = require("axios").default;

const ScenarioMetal = ({ ScenarioState, socket }) => {
  const shiftSize = 7;
  const co2TotalToday = useRecoilValue(Co2TotalTodaySMData);
  const co2TotalTomorrow = useRecoilValue(Co2TotalTomorrowSMData);
  const powderAtomization = useRecoilValue(PowderAutomizationData);
  const volumePArtReduction = useRecoilValue(VolumePartReductionData);
  const co2ePowder = useRecoilValue(Co2ePowderScenarioMData);
  const greenenergymix = useRecoilValue(GreenEnergyMixData);
  const inertGasReduction = useRecoilValue(InertGasReductionSMData);
  const co2Transportation = useRecoilValue(Co2ePerPartTMDataM);
  const machinename = useRecoilValue(MachineNameDataM);
  const materialname = useRecoilValue(MaterialNameDataM);
  const techId = useRecoilValue(technologyId);
  const stepId = useRecoilValue(stepTypeId);
  const techName = useRecoilValue(technologyType);
  const machineId = useRecoilValue(MachineDataM);
  const materialId = useRecoilValue(MaterialDataM);
  //Data For scenario saving -->
  const powderAutomizationInput = useRecoilValue(PowderAutomizationInput);
  const volumePartReductionInput = useRecoilValue(VolumePartReductionInput);
  const greenEnergyMixInput = useRecoilValue(GreenEnergyMixInput);
  const co2eTransportation = useRecoilValue(TotalTraspotation);
  const ModeOfTranspotation1 = useRecoilValue(CO2eModeOfTranspotation1);
  const ModeOfTranspotation2 = useRecoilValue(CO2eModeOfTranspotation2);
  const co2EmissionPowder = useRecoilValue(Co2EmissionPowderDataM);
  const Co2ePerPartPM = useRecoilValue(Co2ePerPartPMDataM);
  const co2eEmissionFactor = useRecoilValue(Co2EmissionFactorDataM);
  const noOfPartsData = useRecoilValue(NoOfPartsDataM);
  const partVolume = useRecoilValue(PartVolumeDataM);
  const partSupportVolume = useRecoilValue(PartSupportVolumeDataM);
  const co2EmissionBuildProcess = useRecoilValue(Co2EmissionBuildProcessDataM);
  const co2EmissionGas = useRecoilValue(Co2EmissionGasDataM);
  const materialTransportaion = useRecoilValue(MaterialTransportationDataM);
  const systemTransportation = useRecoilValue(SystemTransportationDataM);
  const cefnone = useRecoilValue(CarbonEmissionFactorNode1DataM);
  const cefnTwo = useRecoilValue(CarbonEmissionFactorNode2DataM);
  const build_time = useRecoilValue(BuildTimeDataM);
  const [processFlag, setProcessFlag] = useRecoilState(ProcessFlag);

  //Data For scenario saving <--

  const [powder, setPowder] = useState();
  const [volume, setVolume] = useState();
  const [co2powder, setCO2ePowder] = useState();
  const [greenEnergyMix, setGreenEnergyMix] = useState();
  const [inertGas, setInertGas] = useState();
  const [transport, setTransport] = useState();
  const [show, setShow] = useState(false);
  const [scenarioName, setScenarioName] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const DataSet = [
    {
      color: "#5DC0D3",
      title: co2powder
        ? ((co2powder / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2powder / co2TotalToday) * 100).toFixed(2)),
    },
    // TODO..
    // {
    //   color: "#9ceaef",
    //   title: inertGas
    //     ? ((inertGas / co2TotalToday) * 100).toFixed(2) + "%"
    //     : "",
    //   value: parseFloat(((inertGas / co2TotalToday) * 100).toFixed(2)),
    // },
    {
      color: "#9CEAEF",
      title: greenEnergyMix && inertGas
        ? (((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat((((greenEnergyMix + inertGas) / co2TotalToday) * 100).toFixed(2)),
    },
    {
      color: "#07BEB8",
      title: co2eTransportation?.today
        ? ((co2eTransportation?.today / co2TotalToday) * 100).toFixed(2) + "%"
        : "",
      value: parseFloat(((co2eTransportation?.today / co2TotalToday) * 100).toFixed(2)),
    },
  ];
  useEffect(() => {
    if (
      powderAtomization &&
      volumePArtReduction &&
      co2ePowder &&
      greenenergymix &&
      inertGasReduction
    ) {
      setPowder(parseFloat(powderAtomization.today));
      setCO2ePowder(parseFloat(co2ePowder.today));
      setVolume(parseFloat(volumePArtReduction.today));
      setInertGas(parseFloat(inertGasReduction.today));
      setGreenEnergyMix(parseFloat(greenenergymix.today));
      setTransport(parseFloat(co2Transportation));
    }
    const parent = document.getElementById("m2");
    const firstchild = parent.firstChild;
    firstchild.setAttribute("viewBox", "-25 -12 150 125");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    powderAtomization,
    volumePArtReduction,
    co2ePowder,
    greenenergymix,
    inertGasReduction,
  ]);
  const access_token = sessionStorage.getItem("access_token");

  const SaveScenario = async () => {

    toast.info("Please Wait...");
    const formFields = {
      technology_id: techId,
      step_type_id: stepId,
      technology_name: techName,
      machine_name: machinename,
      material_name: materialname,
      material_id: materialId,
      machine_id: machineId,
      co2Transportation: co2Transportation,
      co2EmissionPowder: co2EmissionPowder,
      Co2ePerPartPM: Co2ePerPartPM,
      co2eEmissionFactor: co2eEmissionFactor,
      noOfPartsData: noOfPartsData,
      partVolume: partVolume,
      partSupportVolume: partSupportVolume,
      co2EmissionBuildProcess: co2EmissionBuildProcess,
      co2EmissionGas: co2EmissionGas,
      powderAutomizationInput: powderAutomizationInput,
      volumePartReductionInput: volumePartReductionInput,
      greenEnergyMixInput: greenEnergyMixInput,
      modeOfTranspotation1Input: ModeOfTranspotation1,
      modeOfTranspotation2Input: ModeOfTranspotation2,
      co2eTransportation: co2eTransportation,
      materialTransportaion: materialTransportaion,
      systemTransportation: systemTransportation,
      cefnone: cefnone,
      cefnTwo: cefnTwo,
      build_time: build_time
    };

    const data = {
      scenario_data: JSON.stringify(formFields),
      scenario_name: scenarioName,
    };

    try {
      const response = await axios.post(
        `${config.serviceUrl}client/scenario/save`,
        data,
        {
          headers: {
            Authorization: access_token,
          },
        }
      );

      if (response.data) {
        toast.success("Scenario saved Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setProcessFlag((prevState) => {
      return {
        ...prevState,
        Reports: true,
      };
    });

  }, []);


  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Enter scenario name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-section">
            <DebounceInput
              type="text"
              className="user-input"
              debounceTimeout={500}
              onChange={(e) => {
                setScenarioName(e.target.value);
              }}
              onWheel={(e) => e.target.blur()}
              placeholder="Please enter scenario name"
              value={scenarioName}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center align-items-center w-100">
            <button
              className="selection-button step-button w-50 m-0"
              onClick={handleClose}
            >
              Close
            </button>
            <Button
              disabled={scenarioName.length === 0}
              className="selection-button step-button w-50 mt-0"
              onClick={() => {
                if (scenarioName.length > 0) {
                  SaveScenario();
                  handleClose();
                }
              }}
            >
              Save Scenario
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <button
        onClick={handleShow}
        id="save-scenario"
        className="btn-position selection-button step-button d-flex justify-content-center"
      >
        <Tippy
          content={
            <span>
              You can view your saved scenario by clicking My Scenarios Tab at
              header.
            </span>
          }
        >
          <span>Save My Scenario</span>
        </Tippy>
      </button>
      <div className="powder-container">
        <div className="powder-table-top">
          <p className="powder-heading">Scenario Analysis</p>
        </div>
        <div className="d-flex flex-row p-3">
          <MaterialTable Socket={socket} />
          <MaterialTableValues Socket={socket} />
        </div>
        <div className="powder-table-top total-bottom p-55">
          <p className="powder-heading">
            Total <abbr className="text-lowercase">(kg)</abbr>
          </p>
          <div className="d-flex ">
            <p className="powder-heading metal-scenario-tc">{co2TotalToday}</p>
            <p className="powder-heading metal-scenario-tf">{co2TotalTomorrow}</p>
          </div>
        </div>
      </div>


      <div className="graph-section width--mod">
        <div className="text-center">
          <p className="t-2">CO2e in % of kg per part</p>
          <p className="s-1 mb-0">Current Scenario</p>
        </div>

        <div
          id="m2"
          className="w-100 m-0 h-100 d-flex justify-content-center align-items-center"
        >
          <PieChart
            animationDuration={500}
            animationEasing="ease-out"
            width=" 300px"
            height="300px"
            data={DataSet}
            labelPosition={110}
            lengthAngle={360}
            lineWidth={40}
            paddingAngle={2}
            radius={PieChart.defaultProps.radius - shiftSize}
            startAngle={0}
            label={({ dataEntry }) => dataEntry.title}
            // segmentsShift={(index) => (index === 3 ? shiftSize : 0.2)}

            labelStyle={{
              fill: "black",
              strokeWidth: "10",
              fontSize: "5px",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="d-flex">
          <div className="color-icon bg-color5" />
          <p className="color-rep-value">CO2e Powder</p>
        </div>
        {/* TODO. */}
        {/* <div className="d-flex">
          <div className="color-icon metal-inert-gass" />
          <p className="color-rep-value">CO2e Gas</p>
        </div> */}
        <div className="d-flex">
          <div className="color-icon  bg-color7" />
          <p className="color-rep-value ">CO2e Energy</p>
        </div>
        <div className="d-flex">
          <div className="color-icon bg-color1" />
          <p className="color-rep-value m-0">CO2e Logistics</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => ScenarioState()}
          className="selection-button step-button"
        >
          Generate Report
        </button>
        <button onClick={handleShow} className="selection-button step-button">
          <Tippy
            content={
              <span>
                You can view your saved scenario by clicking My Scenarios Tab at
                header.
              </span>
            }
          >
            <span>Save My Scenario</span>
          </Tippy>
        </button>
      </div>
    </>
  );
};

export default ScenarioMetal;
