import React from "react";
import Navbar from "../components/Nav.jsx";

const Contact = () => {
    sessionStorage.setItem('reportStatus', 'false');
    return (
        <React.Fragment>

            <Navbar />

            <div className="row pad-50 ">
                <div className="col page-container">

                    <p className="t-4 ml-lg-4 mb-2">CONTACT US</p>
                    <div id="post-container" className="container-fluid">
                        <div className="row ">

                            <div className="col-lg-6 col-12 p-0 center" id="contact-holder">
                                <div>
                                    {/* <img src="../assets/map-placeholder.png" alt="" className="img-fluid" /> */}
                                    {/* <iframe  className="iframe-fluid" title="location" frameBorder='0' scrolling="no"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2664.8936866771464!2d11.35535511585269!3d48.0929936622437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479dd6c311480da7%3A0x8e46b028359e9115!2sRobert-Stirling-Ring%201%2C%2082152%20Krailling%2C%20Germany!5e0!3m2!1sen!2s!4v1598448705791!5m2!1sen!2s"></iframe>
                                        <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=2ff4c1ec075ece4ac49b94957ff28ec9ea132723'></script> */}
                                    <iframe className="iframe-fluid" width="100%" title="location" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Robert-Stirling-Ring%201,%2082152%20Krailling,%20Germany+(additive%20minds)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-8 post-text" id="contact-details">
                                <address>
                                    <p className="mb-2" >You are more than welcome to discuss your business case with our experts,</p>
                                    <a href="mailto:Info-additive.minds@eos.info"><u>Info-additive.minds@eos.info</u></a>

                                    <p className="mt-5">Address:</p><br />
                                    <p className="">Robert-Stirling-Ring 1,</p>
                                    <p>82152 Krailling, Germany</p>


                                    <p className="mt-4">For any technical reason related to the tool, </p>
                                    <a href="mailto:syed.nameer@eos.info"><u>syed.nameer@eos.info</u></a>
                                    <p className="mt-1">Syed Nameer,</p>
                                    <p className="">Strategy Consultant, Additive Minds</p>


                                </address>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}

export default Contact;