export const Technology_Name = {
  METAL_SIMULATION: "Metal (Simulation)",
  METAL: "Metal",
  POLYMER_SIMULATION: "Polymer Simulation",
  POLYMER_SIMULATION_STORAGE: "Polymer (Simulation)",
  METAL_ESTIMATION: "Metal (Estimation)",
  POLYMER_ESTIMATION: "Polymer (Estimation)",
}
export const Step_Name_Polymer_Simulation = {
  POST_PROCESSING: "Post Processing",
  MATERIAL_INPUT: "Material Input"
}

export const RESPONSE_MESSAGES = {
  VALUES_NOT_AVAILABLE: 'Values not available for this machine material selection',
  VALUES_ALREACY_DEFAULT: 'Values already set to default',
  VALUES_RESET_DEFAULT: 'Values restored to default',
  CUSTOMIZABLE_VALUE_SAVED: 'Value saved for field',
}
export const FIELD_NAMES = {
  RECURRING_EXPENSE: "recurring_expense",
  SERVICE_EXPENSE: 'service_expense',
  SOFTWARE_EXPENSE: 'software_expense',
  MISCELLANEOUS: "miscellaneous"
}